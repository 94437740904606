import React, { useState, useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
// Import PDFDocument and rgb from pdf-lib
import { TextAlignment } from 'pdf-lib';


import './Certificate.css';
import PropTypes from 'prop-types'; // Import PropTypes
import './DropdownExample.css'



function CertificateGenerator({ firstName, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, coursecertificatr, Gender, Role,To,From,
  department,
  qualification }) {
  console.log("Received props:", { firstName, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, Gender, Role,
    department,To,From,
    qualification });
  const defaultImage = '/image/default.png';

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle undefined or empty values
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [year, month, day] = dateString.split("-");
    return `${parseInt(day)}-${months[parseInt(month) - 1]}-${year}`;
  };

  // Convert the To and From dates
  const formattedFromDate = formatDate(From);
  const formattedToDate = formatDate(To);
  const getImageSource = (regNo) => {
    return `/image/${regNo}.jpg`; // Assuming the image file format is .jpg
  };

  const [qrCodeValue, setQrCodeValue] = useState('');

  const fullurl = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;

  // const handleViewCertificate = () => {
  //   const fullurl = `http://localhost:3000/certificate.html?REG_NO=${REG_NO}`;
  //   setQrCodeValue(fullurl);
  // };

  const capitalize = (str, lower = false) => {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase()
    );
  };


  const getLines = (text, maxWidth, font, fontSize) => {
    const paragraphs = text.split('\n'); // Split text into paragraphs
    let lines = [];
    let y = 400; // Initial y-position

    paragraphs.forEach((paragraph) => {
      const words = paragraph.split(' ');
      let currentLine = '';

      for (let word of words) {
        const width = font.widthOfTextAtSize(currentLine + ' ' + word, fontSize);
        if (width > maxWidth) {
          lines.push(currentLine.trim());
          currentLine = word;
        } else {
          currentLine += ' ' + word;
        }
      }

      if (currentLine !== '') {
        lines.push(currentLine.trim());
      }
    });

    return { lines, y };
  };





//old code
  // const handleGenerateCertificate = async () => {
  //   const trimmedName = firstName ? firstName.trim() : '';


  //   if (trimmedName !== '' && certificate_type !== '') {
  //     try {
  //       let templatePath = '';
  //       if (certificate_type.toLowerCase() === 'internship') {
  //         templatePath = "./cert11.pdf";
  //       } else if (certificate_type.toLowerCase() === 'project') {
  //         templatePath = "./cert2.pdf";
  //       } else if (coursecertificatr.toLowerCase() === 'course') {
  //         templatePath = "./cert3.pdf";
  //       } else {
  //         throw new Error('Invalid course title');
  //       }

  //       const existingPdfBytes = await fetch(templatePath).then((res) =>
  //         res.arrayBuffer()
  //       );

  //       const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //       pdfDoc.registerFontkit(fontkit);

  //       const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );



  //       const nameFont = await pdfDoc.embedFont(nameFontBytes);

  //       const pages = pdfDoc.getPages();
  //       const firstPage = pages[0];

  //       let xPos = 0;
  //       let yPos = 0;
  //       let fontSize = 34;

  //       if (certificate_type.toLowerCase() === 'course') {
  //         xPos = 480;
  //         yPos = 380;
  //       } else if (certificate_type.toLowerCase() === 'internship') {
  //         xPos = 200;
  //         yPos = 470;
  //       } else if (certificate_type.toLowerCase() === 'project') {
  //         xPos = 320;
  //         yPos = 310;
  //         fontSize = 30;
  //       }

  //       const displayName = capitalize(trimmedName);
  //       const { width: pageWidth, height: pageHeight } = firstPage.getSize();
  //       const nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);
  //       const nameX = (pageWidth - nameWidth) / 2;

  //       firstPage.drawText(displayName, {
  //         x: nameX,
  //         y: yPos,
  //         size: fontSize,
  //         font: nameFont,
  //         color: rgb(0, 0, 0),
  //       });



  //       const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );

  //       const boldFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );

  //       const boldFont = await pdfDoc.embedFont(boldFontBytes);

  //       const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);


    





  //       let additionalContent = '';

  //       if (certificate_type.toLowerCase() === 'internship') {
  //         if (Gender === 'MALE') {
  //           additionalContent =
  //             `This is to certify that student from "${CollegeName}" has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at  Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned  project work. During his tenure as a "${Role}" we found him sincere, hardworking, and  result-oriented.\n We wish him great success in all of his future endeavors.`;

  //         } else if (Gender === 'FEMALE') {
  //           additionalContent =
  //             `This is to certify that student from "${CollegeName}" has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at Excerpt Technologies Private Limited. \n She has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\n We wish her great success in all of her future endeavors.`;
  //         }







  //       } else if (certificate_type.toLowerCase() === 'project') {
  //         if (Gender === 'MALE') {
  //           additionalContent =
  //             `This is to certify that student from ${CollegeName} has fsxbsgsfawfegfwaesfzqcompleted their internship work from 8-Jan-2024 to 13-Apr-2024 at  Excerpt Technologies Private Limited. He has successfully & satisfactorily completed assigned  project work. During his tenure as a ${Role} we found him sincere, hardworking, and  result-oriented. We wish him great success in all of his future endeavors.`;
  //         } else if (Gender === 'FEMALE') {
  //           additionalContent =
  //             `This is to certify that student from ${CollegeName}wsdfghsdfghsxdf has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at  Excerpt Technologies Private Limited. He has successfully & satisfactorily completed assigned  project work. During his tenure as a ${Role} we found him sincere, hardworking, and  result-oriented. We wish him great success in all of his future endeavors.`;
  //         }
  //       }

  //       if (certificate_type.toLowerCase() === 'internship') {
  //         if (additionalContent !== '') {
  //           const additionalContentX = 160;
  //           const additionalContentFontSize = 16;


  //           // Draw each line of additional content for internship
  //           const { lines, y } = getLines(additionalContent, 900, mainContentFont, additionalContentFontSize);

  //           lines.forEach((line, index) => {
  //             const lineSpacing = 10; // Adjust the spacing between lines as needed
  //             const yPosition = y - (index * additionalContentFontSize) - (index * lineSpacing); // Adjust spacing between lines as needed
  //             pdfDoc.getPages()[0].drawText(line, {
  //                 x: additionalContentX,
  //                 y: yPosition,
  //                 size: additionalContentFontSize,
  //                 font: mainContentFont,
  //                 color: rgb(0, 0, 0),
  //                 alignment: TextAlignment.Center,
  //             });
  //         });}




  //       } else if (certificate_type.toLowerCase() === 'project') {
  //         if (additionalContent !== '') {
  //           const additionalContentX = 190;
  //           const additionalContentFontSize = 16;

  //           // Draw each line of additional content for project
  //           const { lines, y } = getLines(additionalContent, 850, mainContentFont, additionalContentFontSize);

  //           // Draw each line of additional content
  //           lines.forEach((line, index) => {
  //             pdfDoc.getPages()[0].drawText(line, {
  //               x: additionalContentX,
  //               y: y - (index * 25), // Adjust spacing between lines as needed
  //               size: additionalContentFontSize,
  //               font: mainContentFont,
  //               color: rgb(0, 0, 0),
  //               alignment: TextAlignment.Center,
  //             });
  //           });
  //         }
  //       }





   



  //       // -----------------------------------------------------------qrcode=----------------------------//

  //       const qrCodeOptions = {
  //         color: {
  //           dark: '#000',   // QR code color
  //         },
  //         errorCorrectionLevel: 'H', // High error correction level
  //         width: 200,
  //         type: 'png',
  //         transparent: true, // Make the background transparent
  //       };


  //       const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
  //       setQrCodeValue(linkURL); // Set QR code value to the URL with registration number

  //       const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
  //       const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
  //       const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
  //       const { width, height } = firstPage.getSize();
  //       let qrCodeWidth = 100; // Default width
  //       let qrCodeHeight = 100;
  //       if (certificate_type.toLowerCase() === 'course') {
  //         qrCodeWidth = 120; // Adjusted width for course certificate
  //         qrCodeHeight = 120; // Adjusted height for course certificate
  //       } else if (certificate_type.toLowerCase() === 'internship') {
  //         qrCodeWidth = 150; // Adjusted width for internship certificate
  //         qrCodeHeight = 150; // Adjusted height for internship certificate
  //       } else if (certificate_type.toLowerCase() === 'project') {
  //         qrCodeWidth = 150; // Adjusted width for project certificate
  //         qrCodeHeight = 150; // Adjusted height for project certificate
  //       }

  //       const qrCodeX = 70; // Adjusted for bottom left
  //       const qrCodeY = 50;

  //       firstPage.drawImage(qrCodeImage, {
  //         x: qrCodeX,
  //         y: qrCodeY,
  //         width: qrCodeWidth,
  //         height: qrCodeHeight,
  //       });

  //       const pdfBytes = await pdfDoc.save();
  //       const file = new File(
  //         [pdfBytes],
  //         "Certificate.pdf",
  //         {
  //           type: "application/pdf;charset=utf-8",
  //         }
  //       );

  //       saveAs(file);
  //     } catch (error) {
  //       console.error('Error generating or downloading certificate:', error);
  //       alert('Error generating or downloading certificate. Please try again later.');
  //     }
  //   } else {
  //     alert('Please enter a name and select a certificate type.');
  //   }
  // };
//new for internship code 
//   const handleGenerateCertificate = async () => {
//     const trimmedName = firstName ? firstName.trim() : '';
  
//     if (trimmedName !== '' && certificate_type !== '' && qualification !== '') {
//       try {
//         // Select template based on qualification and certificate type
//         let templatePath = '';
        
//         if (qualification.toUpperCase() === 'BE') {
//           if (certificate_type.toLowerCase() === 'internship') {
//             templatePath = "./BEINTERNSHIP.pdf";
//           } else if (certificate_type.toLowerCase() === 'project') {
//             templatePath = "./BEPROJECT.pdf";
//           }
//         } else if (qualification.toUpperCase() === 'DIPLOMA') {
//           if (certificate_type.toLowerCase() === 'internship') {
//             templatePath = "./DIPLOMAINTERNSHIP.pdf";
//           } else if (certificate_type.toLowerCase() === 'project') {
//             templatePath = "./DIPLOMAPROJECT.pdf";
//           }
//         } else {
//           // Fallback to original templates if qualification doesn't match
//           if (certificate_type.toLowerCase() === 'internship') {
//             templatePath = "./cert11.pdf";
//           } else if (certificate_type.toLowerCase() === 'project') {
//             templatePath = "./cert2.pdf";
//           } else if (certificate_type.toLowerCase() === 'course') {
//             templatePath = "./cert3.pdf";
//           } else {
//             throw new Error('Invalid course title');
//           }
//         }
  
//         const existingPdfBytes = await fetch(templatePath).then((res) =>
//           res.arrayBuffer()
//         );
  
//         const pdfDoc = await PDFDocument.load(existingPdfBytes);
//         pdfDoc.registerFontkit(fontkit);
  
//         const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
//           res.arrayBuffer()
//         );
  
//         const nameFont = await pdfDoc.embedFont(nameFontBytes);
  
//         const pages = pdfDoc.getPages();
//         const firstPage = pages[0];
  
//         let xPos = 0;
//         let yPos = 0;
//         let fontSize = 34;
  
//         // Configure position based on template type
//         if (certificate_type.toLowerCase() === 'course') {
//           xPos = 480;
//           yPos = 380;
//         } else if (certificate_type.toLowerCase() === 'internship') {
//           xPos = 200;
//           yPos = 340;
//         } else if (certificate_type.toLowerCase() === 'project') {
//           xPos = 320;
//           yPos = 310;
//           fontSize = 30;
//         }
  
//         const displayName = capitalize(trimmedName);
//         const { width: pageWidth, height: pageHeight } = firstPage.getSize();
//         const nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);
//         const nameX = (pageWidth - nameWidth) / 2;
  
//         firstPage.drawText(displayName, {
//           x: nameX,
//           y: yPos,
//           size: fontSize,
//           font: nameFont,
//           color: rgb(0, 0, 0),
//         });
  
//         const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
//           res.arrayBuffer()
//         );
  
//         const boldFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
//           res.arrayBuffer()
//         );
  
//         const boldFont = await pdfDoc.embedFont(boldFontBytes);
//         const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
  
//         // let additionalContent = '';
  
//         // if (certificate_type.toLowerCase() === 'internship') {
//         //   if (Gender === 'MALE') {
//         //     additionalContent =
//         //       `This is to certify that student from "${CollegeName}" has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a "${Role}" we found him sincere, hardworking, and result-oriented.\n We wish him great success in all of his future endeavors.`;
//         //   } else if (Gender === 'FEMALE') {
//         //     additionalContent =
//         //       `This is to certify that student from "${CollegeName}" has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at Excerpt Technologies Private Limited. \n She has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\n We wish her great success in all of her future endeavors.`;
//         //   }
//         // } else if (certificate_type.toLowerCase() === 'project') {
//         //   if (Gender === 'MALE') {
//         //     additionalContent =
//         //       `This is to certify that student from ${CollegeName} has completed their project work from 8-Jan-2024 to 13-Apr-2024 at Excerpt Technologies Private Limited. He has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented. We wish him great success in all of his future endeavors.`;
//         //   } else if (Gender === 'FEMALE') {
//         //     additionalContent =
//         //       `This is to certify that student from ${CollegeName} has completed their project work from 8-Jan-2024 to 13-Apr-2024 at Excerpt Technologies Private Limited. She has successfully & satisfactorily completed assigned project work. During her tenure as a ${Role} we found her sincere, hardworking, and result-oriented. We wish her great success in all of her future endeavors.`;
//         //   }
//         // }
  

        
//         // if (certificate_type.toLowerCase() === 'internship') {
//         //   if (additionalContent !== '') {
//         //     const additionalContentX = 120;
//         //     const additionalContentFontSize = 16;
  
//         //     // Draw each line of additional content for internship
//         //     const { lines, y } = getLines(additionalContent, 600, mainContentFont, additionalContentFontSize);
  
//         //     lines.forEach((line, index) => {
//         //       const lineSpacing = 10; // Adjust the spacing between lines as needed
//         //       const yPosition = y - (index * additionalContentFontSize) - (index * lineSpacing);
//         //       pdfDoc.getPages()[0].drawText(line, {
//         //         x: additionalContentX,
//         //         y: yPosition,
//         //         size: additionalContentFontSize,
//         //         font: mainContentFont,
//         //         color: rgb(0, 0, 0),
//         //         alignment: TextAlignment.Center,
//         //       });
//         //     });
//         //   }

//         // if (certificate_type.toLowerCase() === 'internship') {
//         //   if (additionalContent !== '') {
//         //     const additionalContentX = 160; // Adjust as needed
//         //     const additionalContentY = 300; // Move text lower on the page
//         //     const additionalContentFontSize = 16;
        
//         //     // Get lines without overriding the Y position unexpectedly
//         //     const { lines } = getLines(additionalContent, additionalContentY, mainContentFont, additionalContentFontSize);
        
//         //     // Draw each line of additional content for internship
//         //     lines.forEach((line, index) => {
//         //       const lineSpacing = 25; // Adjust line spacing
//         //       const yPosition = additionalContentY - (index * lineSpacing);
        
//         //       console.log(`Rendering line at y: ${yPosition}`); // Debugging log
        
//         //       pdfDoc.getPages()[0].drawText(line, {
//         //         x: additionalContentX,
//         //         y: yPosition,
//         //         size: additionalContentFontSize,
//         //         font: mainContentFont,
//         //         color: rgb(0, 0, 0),
//         //       });
//         //     });
//         //   }
//         // } else if (certificate_type.toLowerCase() === 'project') {
//         //   if (additionalContent !== '') {
//         //     const additionalContentX = 190;
//         //     const additionalContentY = 350; // Lower position for project text
//         //     const additionalContentFontSize = 16;
        
//         //     const { lines } = getLines(additionalContent, additionalContentY, mainContentFont, additionalContentFontSize);
        
//         //     lines.forEach((line, index) => {
//         //       const lineSpacing = 25;
//         //       const yPosition = additionalContentY - (index * lineSpacing);
        
//         //       console.log(`Rendering project text at y: ${yPosition}`);
        
//         //       pdfDoc.getPages()[0].drawText(line, {
//         //         x: additionalContentX,
//         //         y: yPosition,
//         //         size: additionalContentFontSize,
//         //         font: mainContentFont,
//         //         color: rgb(0, 0, 0),
//         //       });
//         //     });
//         //   }
//         // } else if (certificate_type.toLowerCase() === 'course') {
//         //   if (additionalContent !== '') {
//         //     const additionalContentX = 200;
//         //     const additionalContentY = 400; // Lower position for course text
//         //     const additionalContentFontSize = 18;
        
//         //     const { lines } = getLines(additionalContent, additionalContentY, mainContentFont, additionalContentFontSize);
        
//         //     lines.forEach((line, index) => {
//         //       const lineSpacing = 30;
//         //       const yPosition = additionalContentY - (index * lineSpacing);
        
//         //       console.log(`Rendering course text at y: ${yPosition}`);
        
//         //       pdfDoc.getPages()[0].drawText(line, {
//         //         x: additionalContentX,
//         //         y: yPosition,
//         //         size: additionalContentFontSize,
//         //         font: mainContentFont,
//         //         color: rgb(0, 0, 0),
//         //       });
//         //     });
//         //   }
//         // }
        
  
//         // QR Code generation
        
//         let additionalContent = '';
// let additionalContentX = 100; // Default X position
// let additionalContentY = 450; // Default Y position
// let additionalContentFontSize = 14;

// if (certificate_type.toLowerCase() === 'internship') {
//     additionalContentX = 110; // Adjust alignment for Internship
//     additionalContentY = 300; // Lower position
//     if (Gender === 'MALE') {
//         additionalContent = `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.
// He has successfully & satisfactorily completed assigned project work. During his tenure as a "${Role}" we found him sincere, hardworking, and result-oriented.
// We wish him great success in all of his future endeavors.`;
//     } else if (Gender === 'FEMALE') {
//         additionalContent = `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.
// She has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.
// We wish her great success in all of her future endeavors.`;
//     }
// } else if (certificate_type.toLowerCase() === 'project') {
//     additionalContentX = 190; // Adjust alignment for Project
//     additionalContentY = 350; // Different positioning
//     if (Gender === 'MALE') {
//         additionalContent = `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.
//   He has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented.
//        We wish him great success in all of his future endeavors.`;
//     } else if (Gender === 'FEMALE') {
//         additionalContent = `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.
// She has successfully & satisfactorily completed assigned project work. During her tenure as a ${Role} we found her sincere, hardworking, and result-oriented.
// We wish her great success in all of her future endeavors.`;
//     }
// } else if (certificate_type.toLowerCase() === 'course') {
//     additionalContentX = 200; // Adjust alignment for Course
//     additionalContentY = 400; // Different positioning
//     additionalContentFontSize = 18; // Slightly larger font size
//     additionalContent = `This is to certify that student from ${CollegeName} has successfully completed the course at Excerpt Technologies Private Limited.

//     We acknowledge the dedication and effort put in by the student. We wish them all the best in their future endeavors.`;
// }

// // ✅ DRAW TEXT ONLY ONCE AFTER SETTING POSITIONING ✅
// if (additionalContent !== '') {
//     const textWidth = 650; // Adjust width for better readability

//     // Get wrapped lines with proper width
//     const { lines } = getLines(additionalContent, textWidth, mainContentFont, additionalContentFontSize);

//     // Draw text line by line with proper spacing
//     lines.forEach((line, index) => {
//         const lineSpacing = 24; // Adjust spacing
//         const yPosition = additionalContentY - (index * lineSpacing);

//         pdfDoc.getPages()[0].drawText(line, {
//             x: additionalContentX,
//             y: yPosition,
//             size: additionalContentFontSize,
//             font: mainContentFont,
//             color: rgb(0, 0, 0),
//         });
//     });
// }


        
//         const qrCodeOptions = {
//           color: {
//             dark: '#000',   // QR code color
//           },
//           errorCorrectionLevel: 'H', // High error correction level
//           width: 200,
//           type: 'png',
//           transparent: true, // Make the background transparent
//         };
  
//         const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
//         setQrCodeValue(linkURL); // Set QR code value to the URL with registration number
  
//         const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//         const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
//         const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
//         const { width, height } = firstPage.getSize();
//         let qrCodeWidth = 100; // Default width
//         let qrCodeHeight = 100;
        
//         // if (certificate_type.toLowerCase() === 'course') {
//         //   qrCodeWidth = 120; // Adjusted width for course certificate
//         //   qrCodeHeight = 120; // Adjusted height for course certificate
//         // } else if (certificate_type.toLowerCase() === 'internship') {
//         //   qrCodeWidth = 100; // Adjusted width for internship certificate
//         //   qrCodeHeight = 100; // Adjusted height for internship certificate
//         // } else if (certificate_type.toLowerCase() === 'project') {
//         //   qrCodeWidth = 150; // Adjusted width for project certificate
//         //   qrCodeHeight = 150; // Adjusted height for project certificate
//         // }
  
//         // const qrCodeX = 70; // Adjusted for bottom left
//         // const qrCodeY = 50;
//         let qrCodeX = 70; // Default X position
//         let qrCodeY = 50; // Default Y position
        
//         if (certificate_type.toLowerCase() === 'course') {
//           qrCodeX = 500; // Adjusted X position for course
//           qrCodeY = 100; // Adjusted Y position for course
//           qrCodeWidth = 120;
//           qrCodeHeight = 120;
//         } else if (certificate_type.toLowerCase() === 'internship') {
//           qrCodeX = 295; // Adjusted X position for internship
//           qrCodeY = 80; // Adjusted Y position for internship
//           qrCodeWidth = 100;
//           qrCodeHeight = 90;
//         } else if (certificate_type.toLowerCase() === 'project') {
//           qrCodeX = 400; // Adjusted X position for project
//           qrCodeY = 150; // Adjusted Y position for project
//           qrCodeWidth = 150;
//           qrCodeHeight = 150;
//         }
        
//         firstPage.drawImage(qrCodeImage, {
//           x: qrCodeX,
//           y: qrCodeY,
//           width: qrCodeWidth,
//           height: qrCodeHeight,
//         });
  
//         const pdfBytes = await pdfDoc.save();
//         const file = new File(
//           [pdfBytes],
//           "Certificate.pdf",
//           {
//             type: "application/pdf;charset=utf-8",
//           }
//         );
  
//         saveAs(file);
//       } catch (error) {
//         console.error('Error generating or downloading certificate:', error);
//         alert('Error generating or downloading certificate. Please try again later.');
//       }
//     } else {
//       alert('Please enter a name, select a qualification, and select a certificate type.');
//     }
//   };

//correct code for all
// const handleGenerateCertificate = async () => {
//   const trimmedName = firstName ? firstName.trim() : '';

//   if (trimmedName !== '' && certificate_type !== '' && qualification !== '') {
//     try {
//       // Template selection logic (unchanged)
//       let templatePath = '';
//       if (qualification.toUpperCase() === 'BE') {
//         if (certificate_type.toLowerCase() === 'internship') {
//           templatePath = "./BEINTERNSHIP.pdf";
//         } else if (certificate_type.toLowerCase() === 'project') {
//           templatePath = "./BEPROJECT.pdf";
//         }
//       } else if (qualification.toUpperCase() === 'DIPLOMA') {
//         if (certificate_type.toLowerCase() === 'internship') {
//           templatePath = "./DIPLOMAINTERNSHIP.pdf";
//         } else if (certificate_type.toLowerCase() === 'project') {
//           templatePath = "./DIPLOMAPROJECT.pdf";
//         }
//       }

//       const existingPdfBytes = await fetch(templatePath).then((res) =>
//         res.arrayBuffer()
//       );

//       const pdfDoc = await PDFDocument.load(existingPdfBytes);
//       pdfDoc.registerFontkit(fontkit);

//       // Load fonts
//       const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       const boldFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
//         res.arrayBuffer()
//       );

//       const nameFont = await pdfDoc.embedFont(nameFontBytes);
//       const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
//       const boldFont = await pdfDoc.embedFont(boldFontBytes);

//       const firstPage = pdfDoc.getPages()[0];
//       const { width: pageWidth, height: pageHeight } = firstPage.getSize();

//       // Template-specific positioning configuration
//       const templateConfig = {
//         BE: {
//           internship: {
//             name: {
//               x: 'center',
//               y: 340,
//               fontSize: 34
//             },
//             content: {
//               x: 110,
//               y: 300,
//               fontSize: 14,
//               maxWidth: 650
//             },
//             qrCode: {
//               x: 295,
//               y: 80,
//               width: 100,
//               height: 90
//             }
//           },
//           project: {
//             name: {
//               x: 'center',
//               y: 380,
//               fontSize: 30
//             },
//             content: {
//               x: 130,
//               y: 320,
//               fontSize: 14,
//               maxWidth: 650
//             },
//             qrCode: {
//               x: 290,
//               y: 50,
//               width: 100,
//               height: 90
//             }
//           }
//         },
//         DIPLOMA: {
//           internship: {
//             name: {
//               x: 'center',
//               y: 360,
//               fontSize: 32
//             },
//             content: {
//               x: 160,
//               y: 325,
//               fontSize: 14,
//               maxWidth: 600
//             },
//             qrCode: {
//               x: 390,
//               y: 110,
//               width: 80,
//               height: 80
//             }
//           },
//           project: {
//             name: {
//               x: 'center',
//               y: 330,
//               fontSize: 30
//             },
//             content: {
//               x: 140,
//               y: 290,
//               fontSize: 14,
//               maxWidth: 600
//             },
//             qrCode: {
//               x: 370,
//               y: 90,
//               width: 90,
//               height: 90
//             }
//           }
//         }
//       };

//       // Get configuration for current template
//       const config = templateConfig[qualification.toUpperCase()][certificate_type.toLowerCase()];

//       // Draw name
//       const displayName = capitalize(trimmedName);
//       const nameWidth = nameFont.widthOfTextAtSize(displayName, config.name.fontSize);
//       const nameX = config.name.x === 'center' ? (pageWidth - nameWidth) / 2 : config.name.x;

//       firstPage.drawText(displayName, {
//         x: nameX,
//         y: config.name.y,
//         size: config.name.fontSize,
//         font: nameFont,
//         color: rgb(0, 0, 0),
//       });

//       // Generate additional content based on template type
//       let additionalContent = '';
//       if (certificate_type.toLowerCase() === 'internship') {
//         additionalContent = Gender === 'MALE' ?
//           `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a "${Role}" we found him sincere, hardworking, and result-oriented.\nWe wish him great success in all of his future endeavors.` :
//           `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\nWe wish her great success in all of her future endeavors.`;
//       } else if (certificate_type.toLowerCase() === 'project') {
//         additionalContent = Gender === 'MALE' ?
//           `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented.\nWe wish him great success in all of his future endeavors.` :
//           `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a ${Role} we found her sincere, hardworking, and result-oriented.\nWe wish her great success in all of her future endeavors.`;
//       }

//       // Draw additional content
//       if (additionalContent !== '') {
//         const { lines } = getLines(additionalContent, config.content.maxWidth, mainContentFont, config.content.fontSize);
//         lines.forEach((line, index) => {
//           const lineSpacing = 24;
//           const yPosition = config.content.y - (index * lineSpacing);
//           firstPage.drawText(line, {
//             x: config.content.x,
//             y: yPosition,
//             size: config.content.fontSize,
//             font: mainContentFont,
//             color: rgb(0, 0, 0),
//           });
//         });
//       }

//       // Generate and draw QR code
//       const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
//       setQrCodeValue(linkURL);

//       const qrCodeOptions = {
//         color: { dark: '#000' },
//         errorCorrectionLevel: 'H',
//         width: 200,
//         type: 'png',
//         transparent: true,
//       };

//       const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//       const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
//       const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

//       firstPage.drawImage(qrCodeImage, {
//         x: config.qrCode.x,
//         y: config.qrCode.y,
//         width: config.qrCode.width,
//         height: config.qrCode.height,
//       });

//       // Save and download
//       const pdfBytes = await pdfDoc.save();
//       const file = new File(
//         [pdfBytes],
//         "Certificate.pdf",
//         {
//           type: "application/pdf;charset=utf-8",
//         }
//       );

//       saveAs(file);
//     } catch (error) {
//       console.error('Error generating or downloading certificate:', error);
//       alert('Error generating or downloading certificate. Please try again later.');
//     }
//   } else {
//     alert('Please enter a name, select a qualification, and select a certificate type.');
//   }
// };
// const handleGenerateCertificate = async () => {
//   const trimmedName = firstName ? firstName.trim() : '';

//   if (trimmedName !== '' && certificate_type !== '' && qualification !== '') {
//     try {
//       // Template selection logic (unchanged)
//       let templatePath = '';
//       if (qualification.toUpperCase() === 'BE') {
//         if (certificate_type.toLowerCase() === 'internship') {
//           templatePath = "./BEINTERNSHIP.pdf";
//         } else if (certificate_type.toLowerCase() === 'project') {
//           templatePath = "./BEPROJECT.pdf";
//         }
//       } else if (qualification.toUpperCase() === 'DIPLOMA') {
//         if (certificate_type.toLowerCase() === 'internship') {
//           templatePath = "./DIPLOMAINTERNSHIP.pdf";
//         } else if (certificate_type.toLowerCase() === 'project') {
//           templatePath = "./DIPLOMAPROJECT.pdf";
//         }
//       }

//       const existingPdfBytes = await fetch(templatePath).then((res) =>
//         res.arrayBuffer()
//       );

//       const pdfDoc = await PDFDocument.load(existingPdfBytes);
//       pdfDoc.registerFontkit(fontkit);

//       // Load fonts
//       const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       // Using the less bold font for content text
//       const regularFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
//         res.arrayBuffer()
//       );

//       const nameFont = await pdfDoc.embedFont(nameFontBytes);
//       const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
//       const regularFont = await pdfDoc.embedFont(regularFontBytes);

//       const firstPage = pdfDoc.getPages()[0];
//       const { width: pageWidth, height: pageHeight } = firstPage.getSize();

//       // Template-specific positioning configuration
//       const templateConfig = {
//         BE: {
//           internship: {
//             name: {
//               x: 'center',
//               y: 340,
//               fontSize: 34
//             },
//             content: {
//               x: 110,
//               y: 300,
//               fontSize: 14,
//               maxWidth: 680,
//               rightMargin: 110 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 295,
//               y: 80,
//               width: 100,
//               height: 90
//             }
//           },
//           project: {
//             name: {
//               x: 'center',
//               y: 380,
//               fontSize: 30
//             },
//             content: {
//               x: 130,
//               y: 320,
//               fontSize: 14,
//               maxWidth: 650,
//               rightMargin: 130 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 290,
//               y: 50,
//               width: 100,
//               height: 90
//             }
//           }
//         },
//         DIPLOMA: {
//           internship: {
//             name: {
//               x: 'center',
//               y: 360,
//               fontSize: 32
//             },
//             content: {
//               x: 160,
//               y: 325,
//               fontSize: 14,
//               maxWidth: 570,
//               rightMargin: 160 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 390,
//               y: 110,
//               width: 80,
//               height: 80
//             }
//           },
//           project: {
//             name: {
//               x: 'center',
//               y: 330,
//               fontSize: 30
//             },
//             content: {
//               x: 140,
//               y: 290,
//               fontSize: 14,
//               maxWidth: 600,
//               rightMargin: 140 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 370,
//               y: 90,
//               width: 90,
//               height: 90
//             }
//           }
//         }
//       };

//       // Get configuration for current template
//       const config = templateConfig[qualification.toUpperCase()][certificate_type.toLowerCase()];

//       // Draw name
//       const displayName = capitalize(trimmedName);
//       const nameWidth = nameFont.widthOfTextAtSize(displayName, config.name.fontSize);
//       const nameX = config.name.x === 'center' ? (pageWidth - nameWidth) / 2 : config.name.x;

//       firstPage.drawText(displayName, {
//         x: nameX,
//         y: config.name.y,
//         size: config.name.fontSize,
//         font: nameFont,
//         color: rgb(0, 0, 0),
//       });

//       // Generate additional content based on template type
//       let additionalContent = '';
//       if (certificate_type.toLowerCase() === 'internship') {
//         additionalContent = Gender === 'MALE' ?
//           `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a "${Role}" we found him sincere, hardworking, and result-oriented.\nWe wish him great success in all of his future endeavors.` :
//           `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\nWe wish her great success in all of her future endeavors.`;
//       } else if (certificate_type.toLowerCase() === 'project') {
//         additionalContent = Gender === 'MALE' ?
//           `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented.\nWe wish him great success in all of his future endeavors.` :
//           `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a ${Role} we found her sincere, hardworking, and result-oriented.\nWe wish her great success in all of her future endeavors.`;
//       }

//       // Draw additional content with justification
//       if (additionalContent !== '') {
//         const contentParagraphs = additionalContent.split('\n');
//         let currentY = config.content.y;
//         const lineSpacing = 20;

//         contentParagraphs.forEach((paragraph) => {
//           // Get justified lines for this paragraph
//           const justifiedLines = getJustifiedLines(
//             paragraph, 
//             config.content.maxWidth, 
//             regularFont, // Using regular font instead of bold
//             config.content.fontSize,
//             pageWidth - config.content.x - config.content.rightMargin
//           );

//           justifiedLines.forEach((lineInfo) => {
//             // For each word in the justified line
//             lineInfo.words.forEach((word) => {
//               firstPage.drawText(word.text, {
//                 x: config.content.x + word.x,
//                 y: currentY,
//                 size: config.content.fontSize,
//                 font: regularFont, // Using regular font to reduce boldness
//                 color: rgb(0, 0, 0),
//               });
//             });
//             currentY -= lineSpacing;
//           });
          
//           // Add extra spacing between paragraphs
//           currentY -= 4;
//         });
//       }

//       // Generate and draw QR code
//       const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
//       setQrCodeValue(linkURL);

//       const qrCodeOptions = {
//         color: { dark: '#000' },
//         errorCorrectionLevel: 'H',
//         width: 200,
//         type: 'png',
//         transparent: true,
//       };

//       const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//       const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
//       const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

//       firstPage.drawImage(qrCodeImage, {
//         x: config.qrCode.x,
//         y: config.qrCode.y,
//         width: config.qrCode.width,
//         height: config.qrCode.height,
//       });

//       // Save and download
//       const pdfBytes = await pdfDoc.save();
//       const file = new File(
//         [pdfBytes],
//         "Certificate.pdf",
//         {
//           type: "application/pdf;charset=utf-8",
//         }
//       );

//       saveAs(file);
//     } catch (error) {
//       console.error('Error generating or downloading certificate:', error);
//       alert('Error generating or downloading certificate. Please try again later.');
//     }
//   } else {
//     alert('Please enter a name, select a qualification, and select a certificate type.');
//   }
// };
// const handleGenerateCertificate = async () => {
//   const trimmedName = firstName ? firstName.trim() : '';

//   if (trimmedName !== '' && certificate_type !== '' && qualification !== '') {
//     try {
//       // Template selection logic (unchanged)
//       let templatePath = '';
//       if (qualification.toUpperCase() === 'BE') {
//         if (certificate_type.toLowerCase() === 'internship') {
//           templatePath = "./BEINTERNSHIP.pdf";
//         } else if (certificate_type.toLowerCase() === 'project') {
//           templatePath = "./BEPROJECT.pdf";
//         }
//       } else if (qualification.toUpperCase() === 'DIPLOMA') {
//         if (certificate_type.toLowerCase() === 'internship') {
//           templatePath = "./DIPLOMAINTERNSHIP.pdf";
//         } else if (certificate_type.toLowerCase() === 'project') {
//           templatePath = "./DIPLOMAPROJECT.pdf";
//         }
//       }

//       const existingPdfBytes = await fetch(templatePath).then((res) =>
//         res.arrayBuffer()
//       );

//       const pdfDoc = await PDFDocument.load(existingPdfBytes);
//       pdfDoc.registerFontkit(fontkit);

//       // Load fonts
//       const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       // Using the less bold font for content text
//       const regularFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
//         res.arrayBuffer()
//       );

//       const nameFont = await pdfDoc.embedFont(nameFontBytes);
//       const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
//       const regularFont = await pdfDoc.embedFont(regularFontBytes);

//       const firstPage = pdfDoc.getPages()[0];
//       const { width: pageWidth, height: pageHeight } = firstPage.getSize();

//       // Template-specific positioning configuration
//       const templateConfig = {
//         BE: {
//           internship: {
//             name: {
//               x: 'center',
//               y: 340,
//               fontSize: 34
//             },
//             content: {
//               x: 110,
//               y: 305,
//               fontSize: 13.5,
//               maxWidth: 650,
//               rightMargin: 110 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 295,
//               y: 80,
//               width: 100,
//               height: 90
//             }
//           },
//           project: {
//             name: {
//               x: 'center',
//               y: 370,
//               fontSize: 30
//             },
//             content: {
//               x: 130,
//               y: 330,
//               fontSize: 14,
//               maxWidth: 650,
//               rightMargin: 130 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 290,
//               y: 50,
//               width: 100,
//               height: 90
//             }
//           }
//         },
//         DIPLOMA: {
//           internship: {
//             name: {
//               x: 'center',
//               y: 360,
//               fontSize: 32
//             },
//             content: {
//               x: 160,
//               y: 325,
//               fontSize: 13,
//               maxWidth: 620,
//               rightMargin: 160 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 390,
//               y: 110,
//               width: 80,
//               height: 80
//             }
//           },
//           project: {
//             name: {
//               x: 'center',
//               y: 330,
//               fontSize: 30
//             },
//             content: {
//               x: 140,
//               y: 290,
//               fontSize: 13,
//               maxWidth: 620,
//               rightMargin: 140 // Adding right margin for justification
//             },
//             qrCode: {
//               x: 370,
//               y: 90,
//               width: 90,
//               height: 90
//             }
//           }
//         }
//       };

//       // Get configuration for current template
//       const config = templateConfig[qualification.toUpperCase()][certificate_type.toLowerCase()];

//       // Draw name
//       const displayName = capitalize(trimmedName);
//       const nameWidth = nameFont.widthOfTextAtSize(displayName, config.name.fontSize);
//       const nameX = config.name.x === 'center' ? (pageWidth - nameWidth) / 2 : config.name.x;

//       firstPage.drawText(displayName, {
//         x: nameX,
//         y: config.name.y,
//         size: config.name.fontSize,
//         font: nameFont,
//         color: rgb(0, 0, 0),
//       });

//       // Generate additional content based on template type
//       let additionalContent = '';
//       if (certificate_type.toLowerCase() === 'internship') {
//         additionalContent = Gender === 'MALE' ?
//           `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a "${Role}" we found him sincere, hardworking, and result-oriented.\nWe wish him great success in all of his future endeavors.` :
//           `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\nWe wish her great success in all of her future endeavors.`;
//       } else if (certificate_type.toLowerCase() === 'project') {
//         additionalContent = Gender === 'MALE' ?
//           `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented.\nWe wish him great success in all of his future endeavors.` :
//           `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a ${Role} we found her sincere, hardworking, and result-oriented.\nWe wish her great success in all of her future endeavors.`;
//       }

//       // Draw additional content with improved spacing
//       if (additionalContent !== '') {
//         const contentParagraphs = additionalContent.split('\n');
//         let currentY = config.content.y;
//         const lineSpacing = 20;
        
//         // Function to draw text with properly spaced words
//         const drawImprovedText = (text, startX, startY, fontSize, font) => {
//           // Split the text into words
//           const words = text.split(' ');
          
//           // Calculate total text width and available space
//           const totalTextWidth = words.reduce((sum, word, index) => {
//             // Add word width plus space width (except for last word)
//             return sum + font.widthOfTextAtSize(word, fontSize) + 
//                   (index < words.length - 1 ? font.widthOfTextAtSize(' ', fontSize) : 0);
//           }, 0);
          
//           const availableWidth = pageWidth - startX - config.content.rightMargin;
          
//           // For single line or last line, don't justify
//           if (totalTextWidth <= availableWidth || text.endsWith('.')) {
//             let xPos = startX;
//             for (let i = 0; i < words.length; i++) {
//               firstPage.drawText(words[i], {
//                 x: xPos,
//                 y: startY,
//                 size: fontSize,
//                 font: font,
//                 color: rgb(0, 0, 0),
//               });
              
//               // Move to next word position (add word width + space width)
//               xPos += font.widthOfTextAtSize(words[i], fontSize) + 
//                      (i < words.length - 1 ? font.widthOfTextAtSize(' ', fontSize) * 1.5 : 0);
//             }
//             return true; // Successfully drawn
//           }
          
//           // For multi-word lines, calculate justified spacing
//           const spaceCount = words.length - 1;
//           const wordsTotalWidth = words.reduce((sum, word) => 
//             sum + font.widthOfTextAtSize(word, fontSize), 0);
          
//           // Calculate the extra space to distribute between words
//           const extraSpacePerGap = (availableWidth - wordsTotalWidth) / spaceCount;
          
//           // Draw words with justified spacing
//           let xPos = startX;
//           for (let i = 0; i < words.length; i++) {
//             firstPage.drawText(words[i], {
//               x: xPos,
//               y: startY,
//               size: fontSize,
//               font: font,
//               color: rgb(0, 0, 0),
//             });
            
//             // Add normal space plus calculated extra space
//             if (i < words.length - 1) {
//               const standardSpace = font.widthOfTextAtSize(' ', fontSize);
//               xPos += font.widthOfTextAtSize(words[i], fontSize) + standardSpace + extraSpacePerGap;
//             }
//           }
          
//           return true;
//         };

//         // Function to wrap and draw text with proper word spacing
//         const wrapAndDrawText = (text, x, y, maxWidth, fontSize, font) => {
//           const words = text.split(' ');
//           let line = '';
//           let currentY = y;
          
//           for (let i = 0; i < words.length; i++) {
//             const testLine = line + (line ? ' ' : '') + words[i];
//             const testWidth = font.widthOfTextAtSize(testLine, fontSize);
            
//             if (testWidth > maxWidth && line !== '') {
//               // Draw the current line
//               drawImprovedText(line, x, currentY, fontSize, font);
//               line = words[i];
//               currentY -= lineSpacing;
//             } else {
//               line = testLine;
//             }
//           }
          
//           // Draw the last line
//           if (line) {
//             drawImprovedText(line, x, currentY, fontSize, font);
//           }
          
//           return currentY - lineSpacing; // Return the new Y position
//         };

//         // Process each paragraph
//         for (const paragraph of contentParagraphs) {
//           currentY = wrapAndDrawText(
//             paragraph,
//             config.content.x,
//             currentY,
//             config.content.maxWidth,
//             config.content.fontSize,
//             regularFont
//           );
          
//           // Add extra space between paragraphs
//           currentY -= 6;
//         }
//       }

//       // Generate and draw QR code
//       const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
//       setQrCodeValue(linkURL);

//       const qrCodeOptions = {
//         color: { dark: '#000' },
//         errorCorrectionLevel: 'H',
//         width: 200,
//         type: 'png',
//         transparent: true,
//       };

//       const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//       const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
//       const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

//       firstPage.drawImage(qrCodeImage, {
//         x: config.qrCode.x,
//         y: config.qrCode.y,
//         width: config.qrCode.width,
//         height: config.qrCode.height,
//       });

//       // Save and download
//       const pdfBytes = await pdfDoc.save();
//       const file = new File(
//         [pdfBytes],
//         "Certificate.pdf",
//         {
//           type: "application/pdf;charset=utf-8",
//         }
//       );

//       saveAs(file);
//     } catch (error) {
//       console.error('Error generating or downloading certificate:', error);
//       alert('Error generating or downloading certificate. Please try again later.');
//     }
//   } else {
//     alert('Please enter a name, select a qualification, and select a certificate type.');
//   }
// };

const handleGenerateCertificate = async () => {
  const trimmedName = firstName ? firstName.trim() : '';

  if (trimmedName !== '' && certificate_type !== '' && qualification !== '') {
    try {
      // Template selection logic (unchanged)
      let templatePath = '';
      if (qualification.toUpperCase() === 'BE') {
        if (certificate_type.toLowerCase() === 'internship') {
          templatePath = "./BEINTERNSHIP.pdf";
        } else if (certificate_type.toLowerCase() === 'project') {
          templatePath = "./BEPROJECT.pdf";
        }
      } else if (qualification.toUpperCase() === 'DIPLOMA') {
        if (certificate_type.toLowerCase() === 'internship') {
          templatePath = "./DIPLOMAINTERNSHIP.pdf";
        } else if (certificate_type.toLowerCase() === 'project') {
          templatePath = "./DIPLOMAPROJECT.pdf";
        }
      }

      const existingPdfBytes = await fetch(templatePath).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);

      // Load fonts
      const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
        res.arrayBuffer()
      );
      const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
        res.arrayBuffer()
      );
      // Using the less bold font for content text
      const regularFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
        res.arrayBuffer()
      );

      const nameFont = await pdfDoc.embedFont(nameFontBytes);
      const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);

      const firstPage = pdfDoc.getPages()[0];
      const { width: pageWidth, height: pageHeight } = firstPage.getSize();

      // Template-specific positioning configuration
      const templateConfig = {
        BE: {
          internship: {
            name: {
              x: 'center',
              y: 340,
              fontSize: 34
            },
            content: {
              x: 110,
              y: 305,
              fontSize: 13.5,
              maxWidth: 650,
              rightMargin: 110, // Adding right margin for justification
              lineSpacing: 22 // Increased line spacing between lines
            },
            qrCode: {
              x: 295,
              y: 77,
              width: 100,
              height: 90
            }
          },
          project: {
            name: {
              x: 'center',
              y: 370,
              fontSize: 30
            },
            content: {
              x: 130,
              y: 330,
              fontSize: 14,
              maxWidth: 650,
              rightMargin: 130, // Adding right margin for justification
              lineSpacing: 22 // Increased line spacing
            },
            qrCode: {
              x: 290,
              y: 50,
              width: 100,
              height: 90
            }
          }
        },
        DIPLOMA: {
          internship: {
            name: {
              x: 'center',
              y: 360,
              fontSize: 32
            },
            content: {
              x: 160,
              y: 325,
              fontSize: 13,
              maxWidth: 620,
              rightMargin: 160, // Right margin for justification
              lineSpacing: 22 // Increased line spacing
            },
            qrCode: {
              x: 390,
              y: 110,
              width: 80,
              height: 80
            }
          },
          project: {
            name: {
              x: 'center',
              y: 330,
              fontSize: 30
            },
            content: {
              x: 140,
              y: 290,
              fontSize: 13,
              maxWidth: 620,
              rightMargin: 140, // Right margin for justification
              lineSpacing: 22 // Increased line spacing
            },
            qrCode: {
              x: 370,
              y: 90,
              width: 75,
              height: 70
            }
          }
        }
      };

      // Get configuration for current template
      const config = templateConfig[qualification.toUpperCase()][certificate_type.toLowerCase()];

      // Draw name
      const displayName = capitalize(trimmedName);
      const nameWidth = nameFont.widthOfTextAtSize(displayName, config.name.fontSize);
      const nameX = config.name.x === 'center' ? (pageWidth - nameWidth) / 2 : config.name.x;

      firstPage.drawText(displayName, {
        x: nameX,
        y: config.name.y,
        size: config.name.fontSize,
        font: nameFont,
        color: rgb(0, 0, 0),
      });

      // Generate additional content based on template type with proper spacing
      let additionalContent = '';
      if (certificate_type.toLowerCase() === 'internship') {
        additionalContent = Gender === 'MALE' ?
          `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\n\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a "${Role}" we found him sincere, hardworking, and result-oriented.\n\nWe wish him great success in all of his future endeavors.` :
          `This is to certify that student from "${CollegeName}" has completed their internship work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\n\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\n\nWe wish her great success in all of her future endeavors.`;
      } else if (certificate_type.toLowerCase() === 'project') {
        additionalContent = Gender === 'MALE' ?
          `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\n\nHe has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented.\n\nWe wish him great success in all of his future endeavors.` :
          `This is to certify that student from ${CollegeName} has completed their project work from ${formattedFromDate} to ${formattedToDate} at Excerpt Technologies Private Limited.\n\nShe has successfully & satisfactorily completed assigned project work. During her tenure as a ${Role} we found her sincere, hardworking, and result-oriented.\n\nWe wish her great success in all of her future endeavors.`;
      }

      // Improved text drawing functions
      // Function to draw text with proper word spacing
      const drawProperlySpacedText = (text, x, y, fontSize, font) => {
        firstPage.drawText(text, {
          x: x,
          y: y,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      };

      // Function to wrap text based on width and draw with proper spacing
      const wrapAndDrawText = (text, x, y, maxWidth, fontSize, font, lineSpacing) => {
        const words = text.split(' ');
        let line = '';
        let currentY = y;
        
        for (let i = 0; i < words.length; i++) {
          // Add space before word, except for the first word in a line
          const wordToAdd = line === '' ? words[i] : ' ' + words[i];
          const testLine = line + wordToAdd;
          const testWidth = font.widthOfTextAtSize(testLine, fontSize);
          
          if (testWidth > maxWidth && line !== '') {
            // Draw the current line
            drawProperlySpacedText(line, x, currentY, fontSize, font);
            line = words[i];
            currentY -= lineSpacing;
          } else {
            line = testLine;
          }
        }
        
        // Draw the last line
        if (line) {
          drawProperlySpacedText(line, x, currentY, fontSize, font);
        }
        
        return currentY;
      };

      // Process each paragraph with improved spacing
      if (additionalContent !== '') {
        const contentParagraphs = additionalContent.split('\n');
        let currentY = config.content.y;
        const lineSpacing = config.content.lineSpacing || 20;
        const paragraphSpacing = 10; // Additional spacing between paragraphs
        
        for (const paragraph of contentParagraphs) {
          // Skip empty paragraphs but still add spacing
          if (paragraph.trim() === '') {
            currentY -= paragraphSpacing;
            continue;
          }
          
          // Draw paragraph with proper word and line spacing
          currentY = wrapAndDrawText(
            paragraph,
            config.content.x,
            currentY,
            config.content.maxWidth,
            config.content.fontSize,
            regularFont,
            lineSpacing
          );
          
          // Add extra space between paragraphs
          currentY -= paragraphSpacing;
        }
      }

      // Generate and draw QR code
      const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
      setQrCodeValue(linkURL);

      const qrCodeOptions = {
        color: { dark: '#000' },
        errorCorrectionLevel: 'H',
        width: 200,
        type: 'png',
        transparent: true,
      };

      const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
      const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
      const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

      firstPage.drawImage(qrCodeImage, {
        x: config.qrCode.x,
        y: config.qrCode.y,
        width: config.qrCode.width,
        height: config.qrCode.height,
      });

      // Save and download
      const pdfBytes = await pdfDoc.save();
      const file = new File(
        [pdfBytes],
        "Certificate.pdf",
        {
          type: "application/pdf;charset=utf-8",
        }
      );

      saveAs(file);
    } catch (error) {
      console.error('Error generating or downloading certificate:', error);
      alert('Error generating or downloading certificate. Please try again later.');
    }
  } else {
    alert('Please enter a name, select a qualification, and select a certificate type.');
  }
};


  return (

    <>
      <div className="card shadow">
        <div style={{ textAlign: "justify" }}>
          <img className='image' src={getImageSource(REG_NO) || defaultImage} alt="Student" />
          <br />
          <br />
          <p><span className="detail-label">NAME:</span> {firstName}</p>
          <p><span className="detail-label">REG_NO:</span> {REG_NO}</p>
          <p><span className="detail-label">FATHER_NAME:</span> {FATHER_NAME}</p>
          <p><span className="detail-label">COURSETITLE:</span> {coursename}</p>
          <p><span className="detail-label">COOLEGENAME:</span> {CollegeName}</p>
          <p><span className="detail-label">CERTIFICATION:</span> {certificate_type}</p>
          <p><span className="detail-label">YOP:</span> {yop}</p>
          <p><span className="detail-label">COURSECERTIFICATION:</span> {coursecertificatr}</p>
          <p><span className="detail-label">GENDER:</span> {Gender}</p>
          <p><span className="detail-label">Role:</span> {Role}</p>

        </div>
        <div>
          <button onClick={handleGenerateCertificate} style={{ marginLeft: "-9px", width: "200px", marginTop: "10px" }}>Get Certificate</button>
          <a style={{ marginLeft: "180px" }} href={`https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`} target="_blank" rel="noopener noreferrer">View Certificate</a>
        </div>
      </div>

    </>
  );
}

CertificateGenerator.propTypes = {
  firstName: PropTypes.string.isRequired,
  REG_NO: PropTypes.string.isRequired,
  FATHER_NAME: PropTypes.string.isRequired,
  coursename: PropTypes.string.isRequired,
  CollegeName: PropTypes.string.isRequired,
  Course_Title: PropTypes.string.isRequired,


  certificate_type: PropTypes.string.isRequired,
  yop: PropTypes.string.isRequired
};

export default CertificateGenerator;
