// import React, { useState } from 'react';
// import './Dasboard.css';
// import { Link } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
// import Certificate from '../Pages/Certificate';
// import Resumes from '../Components/Resumes';
// import Studentform from '../Components/Certificate/Studentform'
// import Studentcertificate from '../Components/Studentcertificate.js/Studentcertificate'
// import StudentForm from '../Components/Certificate/Studentform';
// import Invoice from './Invoice/Invoice';
// import Studentformfoeeng from '../Components/Certificate/Studentformfoeeng';
// const Dashboard = () => {
//     const location = useLocation();
//     const username = location.state ? location.state.username : '';
//     const [showCertificate, setShowCertificate] = useState(false);
//     const [showResume, setshowResume] = useState(false);
//     const [showwStudentForm, setStudentForm] = useState(false);
//     const [showStudentForm, setshowStudentForm] = useState(false);
//     const [showinternshipCertificate, setshowinternshipCertificate] = useState(false)
//     return (
//         <div>
//             <div class="container-scroller d-flex">

//                 <nav class="sidebar sidebar-offcanvas" id="sidebar">
//                     <ul class="nav">
//                         <li class="nav-item sidebar-category">
                           
//                             <span></span>
//                         </li>
//                         <li class="nav-item" >
//                             {/* <a class="nav-link" href="">
//                                 <i class="mdi mdi-view-quilt menu-icon"></i>
//                                 <span class="menu-title">Dashboard</span>
//                                 <div class="badge badge-info badge-pill">2</div>
//                             </a> */}

//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' style={{width:"160px",marginLeft:"20px"}}>
//                                 Dasboard
                               
//                             </button>
//                         </li>
//                         {/* <li class="nav-item sidebar-category">
                    
//                             <span></span>
//                         </li> */}
//                          <li className="nav-item" style={{margin:"20px"}}>
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setStudentForm(!showwStudentForm)}>
//                             StudentFormdeploma
//                             </button>
//                         </li>

//                         <li className="nav-item" style={{margin:"20px"}}>
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setShowCertificate(!showCertificate)}>
//                                 Certificate
//                             </button>
//                         </li>

//                         <li className="nav-item" style={{margin:"20px"}}>
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setshowResume(!showResume)}>
//                                 Resumes
//                             </button>
//                         </li>
//                         <li className="nav-item" style={{margin:"20px"}}>
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setshowStudentForm(!showStudentForm)}>
//                                 StudentForm
//                             </button>
//                         </li>
//                         <li className="nav-item" style={{margin:"20px"}}>
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setshowinternshipCertificate(!showinternshipCertificate)}>
//                                 internship Certificate
//                             </button>
//                         </li>
//                         <li className="nav-item" style={{margin:"20px"}}>
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow'> <Link to="/invoice" style={{color:"white"}}> Invoice</Link>
                             
//                                </button>
//                         </li>
//                         {/* <li className="nav-item">
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setshowResume(!showResume)}>
//                                 Resumes
//                             </button>
//                         </li> */}



//                         <li class="nav-item">
//                             {/* <a class="nav-link" href="">
//                                 <i class="mdi mdi-emoticon menu-icon"></i>
//                                 <span class="menu-title">Profile</span>
//                             </a> */}
//                         </li>
//                         <li class="nav-item" style={{margin:"20px"}}>
//                             {/* <a class="nav-link" href="/">
//                                 <i class="mdi mdi-emoticon menu-icon"></i>
//                                 <span class="menu-title">Logout</span>
//                             </a> */}
//                             <button className='mdi mdi-palette menu-icon menu-title menu-arrow'> <Link to="/" style={{color:"white"}}>Logout</Link>
                             
//                              </button>
//                         </li>



//                     </ul>
//                 </nav>

//                 <div class="container-fluid page-body-wrapper">

//                     <nav class="navbar col-lg-12 col-12 px-0 py-0 py-lg-4 d-flex flex-row" />
//                     <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
//                         <button class="" type="button" data-toggle="minimize">
//                             <span class="mdi mdi-menu" style={{ color: "black", marginLeft: "-810px" }}></span>
//                         </button>


//                         <ul class="navbar-nav navbar-nav-right">
//                             <li class="nav-item">
//                                 <h4 class="mb-0 font-weight-bold d-none d-xl-block"></h4>
//                             </li>
//                             <li class="nav-item dropdown mr-1">

//                                 <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
//                                     <p class="mb-0 font-weight-normal float-left dropdown-header">Messages</p>

//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <img src="images/faces/face2.jpg" alt="image" class="profile-pic" />
//                                         </div>
//                                         <div class="preview-item-content flex-grow">
//                                             <h6 class="preview-subject ellipsis font-weight-normal">Tim Cook
//                                             </h6>
//                                             <p class="font-weight-light small-text text-muted mb-0">
//                                                 New product launch
//                                             </p>
//                                         </div>
//                                     </a>
//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <img src="images/faces/face3.jpg" alt="image" class="profile-pic" />
//                                         </div>
//                                         <div class="preview-item-content flex-grow">
//                                             <h6 class="preview-subject ellipsis font-weight-normal"> Johnson
//                                             </h6>
//                                             <p class="font-weight-light small-text text-muted mb-0">
//                                                 Upcoming board meeting
//                                             </p>
//                                         </div>
//                                     </a>
//                                 </div>
//                             </li>
//                             <li class="nav-item dropdown mr-2">
//                                 <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">




//                                 </div>
//                             </li>
//                         </ul>

//                     </div>



//                     <div class="main-panel">
//                         <h4 class="font-weight-bold mb-0 d-none d-md-block mt-1">Welcome back, Admin</h4>
//                         <div class="content-wrapper">

//                             {/* {showCertificate && <Certificate /> && <Studentformfoeeng/>} */}
//                             {showCertificate && <Certificate /> }
//                             {showResume && <Resumes />}
//                             {showStudentForm && <Studentform />}
//                             {showinternshipCertificate && <Studentcertificate />}


//                         </div>



//                     </div>

//                 </div>

//             </div>
//         </div>
//     )
// }

// export default Dashboard


import React, { useState } from 'react';
import './Dasboard.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Certificate from '../Pages/Certificate';
import Resumes from '../Components/Resumes';
import Studentform from '../Components/Certificate/Studentform';
import Studentcertificate from '../Components/Studentcertificate.js/Studentcertificate';
import StudentForm from '../Components/Certificate/Studentform';
import Invoice from './Invoice/Invoice';
import Studentformfoeeng from '../Components/Certificate/Studentformfoeeng';

const Dashboard = () => {
    const location = useLocation();
    const username = location.state ? location.state.username : '';
    const [showCertificate, setShowCertificate] = useState(false);
    const [showResume, setshowResume] = useState(false);
    const [showStudentFormDiploma, setShowStudentFormDiploma] = useState(false);
    const [showStudentForm, setshowStudentForm] = useState(false);
    const [showinternshipCertificate, setshowinternshipCertificate] = useState(false);

    // Helper function to reset all view states
    const resetViews = () => {
        setShowCertificate(false);
        setshowResume(false);
        setShowStudentFormDiploma(false);
        setshowStudentForm(false);
        setshowinternshipCertificate(false);
    };

    // Modified click handlers to reset other views
    const handleViewChange = (setter) => {
        resetViews();
        setter(true);
    };

    return (
        <div>
            <div className="container-scroller d-flex">
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <ul className="nav">
                        <li className="nav-item sidebar-category">
                            <span></span>
                        </li>
                        <li className="nav-item">
                            <button className='mdi mdi-palette menu-icon menu-title menu-arrow' style={{width:"160px",marginLeft:"20px"}}>
                                Dashboard
                            </button>
                        </li>
                        
                        <li className="nav-item" style={{margin:"20px"}}>
                            <button 
                                className='mdi mdi-palette menu-icon menu-title menu-arrow' 
                                onClick={() => handleViewChange(setShowStudentFormDiploma)}
                            >
                                StudentFormdeploma
                            </button>
                        </li>

                        <li className="nav-item" style={{margin:"20px"}}>
                            <button 
                                className='mdi mdi-palette menu-icon menu-title menu-arrow' 
                                onClick={() => handleViewChange(setShowCertificate)}
                            >
                                Certificate
                            </button>
                        </li>

                        <li className="nav-item" style={{margin:"20px"}}>
                            <button 
                                className='mdi mdi-palette menu-icon menu-title menu-arrow' 
                                onClick={() => handleViewChange(setshowResume)}
                            >
                                Resumes
                            </button>
                        </li>

                        <li className="nav-item" style={{margin:"20px"}}>
                            <button 
                                className='mdi mdi-palette menu-icon menu-title menu-arrow' 
                                onClick={() => handleViewChange(setshowStudentForm)}
                            >
                                StudentForm
                            </button>
                        </li>

                        <li className="nav-item" style={{margin:"20px"}}>
                            <button 
                                className='mdi mdi-palette menu-icon menu-title menu-arrow' 
                                onClick={() => handleViewChange(setshowinternshipCertificate)}
                            >
                                internship Certificate
                            </button>
                        </li>

                        <li className="nav-item" style={{margin:"20px"}}>
                            <button className='mdi mdi-palette menu-icon menu-title menu-arrow'>
                                <Link to="/invoice" style={{color:"white"}}>Invoice</Link>
                            </button>
                        </li>

                        <li className="nav-item" style={{margin:"20px"}}>
                            <button className='mdi mdi-palette menu-icon menu-title menu-arrow'>
                                <Link to="/" style={{color:"white"}}>Logout</Link>
                            </button>
                        </li>
                    </ul>
                </nav>

                <div className="container-fluid page-body-wrapper">
                    <nav className="navbar col-lg-12 col-12 px-0 py-0 py-lg-4 d-flex flex-row" />
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                        <button className="" type="button" data-toggle="minimize">
                            <span className="mdi mdi-menu" style={{ color: "black", marginLeft: "-810px" }}></span>
                        </button>

                        <ul className="navbar-nav navbar-nav-right">
                            <li className="nav-item">
                                <h4 className="mb-0 font-weight-bold d-none d-xl-block"></h4>
                            </li>
                        </ul>
                    </div>

                    <div className="main-panel">
                        <h4 className="font-weight-bold mb-0 d-none d-md-block mt-1">Welcome back, Admin</h4>
                        <div className="content-wrapper">
                            {showStudentFormDiploma && <Studentformfoeeng />}
                            {showCertificate && <Certificate />}
                            {showResume && <Resumes />}
                            {showStudentForm && <Studentform />}
                            {showinternshipCertificate && <Studentcertificate />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;