// import React, { useState, useEffect } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './InternForm.css';

// const InternRegistrationForm = () => {
//   const [formData, setFormData] = useState({
//     NAME: '',
//     GENDER: '',
//     FATHER_NAME: '',
//     REG_NO: '',
//     PHOTO: '',
//     college_id: '',
//     Course_id: '',
//     Certificate_Type_id: '',
//     Course_Certificate_Type_id:'',
//     yop: '',
//     Role: '',
//     From: '',
//     To: '',
//     qualification: '',
//     department: ''
//   });

//   const [colleges, setColleges] = useState([]);
//   const [courses, setCourses] = useState([]);
//   const [certificateTypes, setCertificateTypes] = useState([]);

//   const qualifications = ['BE', 'Diploma'];
//   const genders = ['MALE', 'FEMALE', 'OTHER'];
// const Course_Certificate_Type_id=[301,302]

//   useEffect(() => {
//     fetchLookupData();
//   }, []);

//   const fetchLookupData = async () => {
//     try {
//       const [collegesRes, coursesRes, certificateTypesRes] = await Promise.all([
//         fetch('/api/colleges'),
//         fetch('/api/courses'),
//         fetch('/api/certificateTypes')
//       ]);

//       const collegesData = await collegesRes.json();
//       const coursesData = await coursesRes.json();
//       const certificateTypesData = await certificateTypesRes.json();

//       console.log('Colleges:', collegesData);
//       console.log('Courses:', coursesData);
//       console.log('Certificate Types:', certificateTypesData);

//       setColleges(collegesData);
//       setCourses(coursesData);
//       setCertificateTypes(certificateTypesData);
//     } catch (error) {
//       toast.error('Error fetching lookup data');
//     }
//   };

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData((prev) => ({ ...prev, [id]: value }));
//   };

//   const handleSelectionChange = (e, id, options, idKey) => {
//     const selectedIndex = e.target.selectedIndex;
//     if (selectedIndex === 0) {
//       setFormData(prev => ({ ...prev, [id]: '' }));
//       return;
//     }

//     const selectedOption = options[selectedIndex - 1];
//     setFormData(prev => ({ ...prev, [id]: selectedOption[idKey] }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     console.log('Submitting:', formData); // Debugging
    
//     try {
//       const response = await fetch('/api/interns', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData)
//       });

//       if (response.ok) {
//         toast.success('Intern data added successfully!');
//         resetForm();
//       } else {
//         const errorData = await response.json();
//         throw new Error(errorData.message || 'Failed to add intern');
//       }
//     } catch (error) {
//       toast.error('Error submitting form: ' + error.message);
//     }
//   };

//   const resetForm = () => {
//     setFormData({
//       NAME: '', GENDER: '', FATHER_NAME: '', REG_NO: '', PHOTO: '',Course_Certificate_Type_id:'',
//       college_id: '', Course_id: '', Certificate_Type_id: '',
//       yop: '', Role: '', From: '', To: '', qualification: '', department: ''
//     });
//   };

//   return (
//     <main>
//       <div className="form-container">
//         <h2>Intern Registration Form</h2>
//         <ToastContainer />

//         <form onSubmit={handleSubmit}>
//           {['NAME', 'FATHER_NAME', 'department','REG_NO', 'Role','yop'].map(field => (
//             <div className="input-container" key={field}>
//               <label htmlFor={field}>{field.replace('_', ' ')}</label>
//               <input type="text" id={field} value={formData[field]} onChange={handleChange} required />
//             </div>
//           ))}

//           {[{ id: 'GENDER', options: genders },{ id: 'Course_Certificate_Type_id', options: Course_Certificate_Type_id }, { id: 'qualification', options: qualifications }].map(({ id, options }) => (
//             <div className="input-container" key={id}>
//               <label htmlFor={id}>{id.replace('_', ' ')}</label>
//               <select id={id} value={formData[id]} onChange={handleChange} required>
//                 <option value="">Select {id.replace('_', ' ')}</option>
//                 {options.map((opt, index) => <option key={index} value={opt}>{opt}</option>)}
//               </select>
//             </div>
//           ))}

//           {[{ id: 'college_id', options: colleges, idKey: '_id' },
//             { id: 'Course_id', options: courses, idKey: 'courseId' },
//             { id: 'Certificate_Type_id', options: certificateTypes, idKey: 'Certificate_Type_id' }]
//             .map(({ id, options, idKey }) => (
//             <div className="input-container" key={id} style={{color:'black'}}>
//               <label htmlFor={id}>{id.replace('_', ' ')}</label>
//               <select id={id} value={formData[id]} onChange={(e) => handleSelectionChange(e, id, options, idKey)} required>
//                 <option value="" style={{color:'black'}}>Select {id.replace('_', ' ')}</option>
//                 {options.map(opt => <option key={opt[idKey]} value={opt[idKey]}>{opt.name}</option>)}
//               </select>
//             </div>
//           ))}

//           {[{ id: 'From', label: 'From Date' }, { id: 'To', label: 'To Date' }].map(({ id, label }) => (
//             <div className="input-container" key={id}>
//               <label htmlFor={id}>{label}</label>
//               <input type="date" id={id} value={formData[id]} onChange={handleChange} required />
//             </div>
//           ))}

//           <div className="submit-container">
//             <button type="submit">Submit</button>
//           </div>
//         </form>
//       </div>
//     </main>
//   );
// };

// export default InternRegistrationForm;


// import React, { useState, useEffect } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const InternRegistrationForm = () => {
//   const [formData, setFormData] = useState({
//     NAME: '',
//     GENDER: '',
//     FATHER_NAME: '',
//     REG_NO: '',
//     PHOTO: '',
//     college_id: '',
//     Course_id: '',
//     Certificate_Type_id: '',
//     Course_Certificate_Type_id: '',
//     yop: '',
//     Role: '',
//     From: '',
//     To: '',
//     qualification: '',
//     department: ''
//   });

//   const [colleges, setColleges] = useState([]);
//   const [courses, setCourses] = useState([]);
//   const [certificateTypes, setCertificateTypes] = useState([]);

//   const qualifications = ['BE', 'Diploma'];
//   const genders = ['MALE', 'FEMALE', 'OTHER'];
//    const Course_Certificate_Type_id = [301, 302];
//   const courseCertificateOptions = {
//     301: "Internship",
//     302: "Project"
//   };
//   useEffect(() => {
//     fetchLookupData();
//   }, []);

//   const fetchLookupData = async () => {
//     try {
//       const [collegesRes, coursesRes, certificateTypesRes] = await Promise.all([
//         fetch('/api/colleges'),
//         fetch('/api/courses'),
//         fetch('/api/certificateTypes')
//       ]);

//       const collegesData = await collegesRes.json();
//       const coursesData = await coursesRes.json();
//       const certificateTypesData = await certificateTypesRes.json();

//       setColleges(collegesData);
//       setCourses(coursesData);
//       setCertificateTypes(certificateTypesData);
//     } catch (error) {
//       toast.error('Error fetching lookup data');
//       console.error('Fetch error:', error);
//     }
//   };

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData((prev) => ({ ...prev, [id]: value }));
//   };

//   const handleCollegeChange = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     if (selectedIndex === 0) {
//       setFormData(prev => ({ ...prev, college_id: '' }));
//       return;
//     }
//     const selectedCollege = colleges[selectedIndex - 1];
//     setFormData(prev => ({ ...prev, college_id: selectedCollege._id }));
//   };

//   const handleCourseChange = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     if (selectedIndex === 0) {
//       setFormData(prev => ({ ...prev, Course_id: '' }));
//       return;
//     }
//     const selectedCourse = courses[selectedIndex - 1];
//     setFormData(prev => ({ ...prev, Course_id: selectedCourse.courseId }));
//   };

//   const handleCertificateTypeChange = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     if (selectedIndex === 0) {
//       setFormData(prev => ({ ...prev, Certificate_Type_id: '' }));
//       return;
//     }
//     const selectedCertType = certificateTypes[selectedIndex - 1];
//     setFormData(prev => ({ ...prev, Certificate_Type_id: selectedCertType.Certificate_Type_id }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch('/api/interns', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData)
//       });

//       if (response.ok) {
//         toast.success('Intern data added successfully!');
//         resetForm();
//       } else {
//         const errorData = await response.json();
//         throw new Error(errorData.message || 'Failed to add intern');
//       }
//     } catch (error) {
//       toast.error('Error submitting form: ' + error.message);
//     }
//   };

//   const resetForm = () => {
//     setFormData({
//       NAME: '', GENDER: '', FATHER_NAME: '', REG_NO: '', PHOTO: '', Course_Certificate_Type_id: '',
//       college_id: '', Course_id: '', Certificate_Type_id: '',
//       yop: '', Role: '', From: '', To: '', qualification: '', department: ''
//     });
//   };

//   return (
//     <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md">
//       <h2 className="text-2xl font-bold mb-6 text-center">Intern Registration Form</h2>
//       <ToastContainer />

//       <form onSubmit={handleSubmit} className="space-y-4">
//         {/* Text input fields */}
//         <div className="flex flex-col">
//           <label htmlFor="NAME" className="mb-1 font-medium">Name</label>
//           <input
//             type="text"
//             id="NAME"
//             value={formData.NAME}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label htmlFor="FATHER_NAME" className="mb-1 font-medium">Father Name</label>
//           <input
//             type="text"
//             id="FATHER_NAME"
//             value={formData.FATHER_NAME}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label htmlFor="department" className="mb-1 font-medium">Department</label>
//           <input
//             type="text"
//             id="department"
//             value={formData.department}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label htmlFor="REG_NO" className="mb-1 font-medium">Registration No</label>
//           <input
//             type="text"
//             id="REG_NO"
//             value={formData.REG_NO}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label htmlFor="Role" className="mb-1 font-medium">Role</label>
//           <input
//             type="text"
//             id="Role"
//             value={formData.Role}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label htmlFor="yop" className="mb-1 font-medium">Year of Passing</label>
//           <input
//             type="text"
//             id="yop"
//             value={formData.yop}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         {/* Gender dropdown */}
//         <div className="flex flex-col">
//           <label htmlFor="GENDER" className="mb-1 font-medium">Gender</label>
//           <select
//             id="GENDER"
//             value={formData.GENDER}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           >
//             <option value="">Select Gender</option>
//             {genders.map((gender, index) => (
//               <option key={index} value={gender}>
//                 {gender}
//               </option>
//             ))}
//           </select>
//         </div>

//         {/* Course Certificate Type dropdown */}
//         {/* <div className="flex flex-col">
//           <label htmlFor="Course_Certificate_Type_id" className="mb-1 font-medium">Course Certificate Type</label>
//           <select
//             id="Course_Certificate_Type_id"
//             value={formData.Course_Certificate_Type_id}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           >
//             <option value="">Select Course Certificate Type</option>
//             {Course_Certificate_Type_id.map((type, index) => (
//               <option key={index} value={type}>
//                 {type}
//               </option>
//             ))}
//           </select>
//         </div> */}


// <div className="flex flex-col">
//   <label htmlFor="Course_Certificate_Type_id" className="mb-1 font-medium">
//     Course Certificate Type
//   </label>
//   <select
//     id="Course_Certificate_Type_id"
//     value={formData.Course_Certificate_Type_id}
//     onChange={handleChange}
//     required
//     className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//   >
//     <option value="">Select Course Certificate Type</option>
//     {Course_Certificate_Type_id.map((type) => (
//       <option key={type} value={type}>
//         {courseCertificateOptions[type] || "Unknown Certificate"}
//       </option>
//     ))}
//   </select>
// </div>;


//         {/* Qualification dropdown */}
//         <div className="flex flex-col">
//           <label htmlFor="qualification" className="mb-1 font-medium">Qualification</label>
//           <select
//             id="qualification"
//             value={formData.qualification}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           >
//             <option value="">Select Qualification</option>
//             {qualifications.map((qual, index) => (
//               <option key={index} value={qual}>
//                 {qual}
//               </option>
//             ))}
//           </select>
//         </div>

//         {/* College dropdown - explicitly handled */}
//         <div className="flex flex-col">
//           <label htmlFor="college_id" className="mb-1 font-medium">College</label>
//           <select
//             id="college_select"
//             onChange={handleCollegeChange}
//             required
//             className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           >
//             <option value="">Select College</option>
//             {colleges.map(college => (
//               <option 
//                 key={college._id} 
//                 value={college._id}
//               >
//                 {college.name || "College Name Missing"}
//               </option>
//             ))}
//           </select>
//           <input type="hidden" id="college_id" value={formData.college_id} />
//         </div>

//         {/* Course dropdown - explicitly handled */}
//         <div className="flex flex-col">
//           <label htmlFor="Course_id" className="mb-1 font-medium">Course</label>
//           <select
//             id="course_select"
//             onChange={handleCourseChange}
//             required
//             className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           >
//             <option value="">Select Course</option>
//             {courses.map(course => (
//               <option 
//                 key={course.courseId || course._id} 
//                 value={course.courseId || ""}
//               >
//                 {course.courseName || course.name || "Course Name Missing"}
//               </option>
//             ))}
//           </select>
//           <input type="hidden" id="Course_id" value={formData.Course_id} />
//         </div>

//         {/* Certificate Type dropdown - explicitly handled */}
//         <div className="flex flex-col">
//           <label htmlFor="Certificate_Type_id" className="mb-1 font-medium">Certificate Type</label>
//           <select
//   id="certificate_select"
//   onChange={handleCertificateTypeChange}
//   required
//   className="p-2 border rounded bg-white text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
// >
//   <option value="">Select Certificate Type</option>
//   {certificateTypes.map(certType => (
//     <option 
//       key={certType._id} 
//       value={certType.Certificate_Type_id || certType.Course_Certificate_Type_id || ""}
//     >
//       {certType.Certificate_Name || certType.Certification_Name || "Certificate Type Missing"}
//     </option>
//   ))}
// </select>

//           <input type="hidden" id="Certificate_Type_id" value={formData.Certificate_Type_id} />
//         </div>

//         {/* Date inputs */}
//         <div className="flex flex-col">
//           <label htmlFor="From" className="mb-1 font-medium">From Date</label>
//           <input
//             type="date"
//             id="From"
//             value={formData.From}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label htmlFor="To" className="mb-1 font-medium">To Date</label>
//           <input
//             type="date"
//             id="To"
//             value={formData.To}
//             onChange={handleChange}
//             required
//             className="p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           />
//         </div>

//         <div className="mt-6">
//           <button
//             type="submit"
//             className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default InternRegistrationForm;



import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InternRegistrationForm = () => {
  const [formData, setFormData] = useState({
    NAME: '',
    GENDER: '',
    FATHER_NAME: '',
    REG_NO: '',
    PHOTO: '',
    college_id: '',
    Course_id: '',
    Certificate_Type_id: '',
    Course_Certificate_Type_id: '',
    yop: '',
    Role: '',
    From: '',
    To: '',
    qualification: '',
    department: ''
  });

  const [colleges, setColleges] = useState([]);
  const [courses, setCourses] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState([]);

  const qualifications = ['BE', 'Diploma'];
  const genders = ['MALE', 'FEMALE', 'OTHER'];
  const Course_Certificate_Type_id = [301, 302];
  const courseCertificateOptions = {
    301: "Internship",
    302: "Project"
  };
  
  useEffect(() => {
    fetchLookupData();
  }, []);

  const fetchLookupData = async () => {
    try {
      const [collegesRes, coursesRes, certificateTypesRes] = await Promise.all([
        fetch('/api/colleges'),
        fetch('/api/courses'),
        fetch('/api/certificateTypes')
      ]);

      const collegesData = await collegesRes.json();
      const coursesData = await coursesRes.json();
      const certificateTypesData = await certificateTypesRes.json();

      setColleges(collegesData);
      setCourses(coursesData);
      setCertificateTypes(certificateTypesData);
    } catch (error) {
      toast.error('Error fetching lookup data');
      console.error('Fetch error:', error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleCollegeChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    if (selectedIndex === 0) {
      setFormData(prev => ({ ...prev, college_id: '' }));
      return;
    }
    const selectedCollege = colleges[selectedIndex - 1];
    setFormData(prev => ({ ...prev, college_id: selectedCollege._id }));
  };

  const handleCourseChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    if (selectedIndex === 0) {
      setFormData(prev => ({ ...prev, Course_id: '' }));
      return;
    }
    const selectedCourse = courses[selectedIndex - 1];
    setFormData(prev => ({ ...prev, Course_id: selectedCourse.courseId }));
  };

  const handleCertificateTypeChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    if (selectedIndex === 0) {
      setFormData(prev => ({ ...prev, Certificate_Type_id: '' }));
      return;
    }
    const selectedCertType = certificateTypes[selectedIndex - 1];
    setFormData(prev => ({ ...prev, Certificate_Type_id: selectedCertType.Certificate_Type_id }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/interns', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        toast.success('Intern data added successfully!');
        resetForm();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add intern');
      }
    } catch (error) {
      toast.error('Error submitting form: ' + error.message);
    }
  };

  const resetForm = () => {
    setFormData({
      NAME: '', GENDER: '', FATHER_NAME: '', REG_NO: '', PHOTO: '', Course_Certificate_Type_id: '',
      college_id: '', Course_id: '', Certificate_Type_id: '',
      yop: '', Role: '', From: '', To: '', qualification: '', department: ''
    });
  };

  // Internal CSS styles
  const styles = {
    formContainer: {
      padding: '2rem',
      maxWidth: '900px',
      margin: '0 auto',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
      fontFamily: 'system-ui, -apple-system, sans-serif'
    },
    formTitle: {
      fontSize: '1.75rem',
      fontWeight: '700',
      marginBottom: '1.5rem',
      textAlign: 'center',
      color: '#1f2937'
    },
    formSection: {
      marginBottom: '1.5rem'
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '1rem',
      marginBottom: '1rem'
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem'
    },
    label: {
      marginBottom: '0.5rem',
      fontWeight: '500',
      color: '#374151'
    },
    input: {
      padding: '0.75rem',
      border: '1px solid #d1d5db',
      borderRadius: '0.375rem',
      fontSize: '1rem',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      outline: 'none'
    },
    select: {
      padding: '0.75rem',
      border: '1px solid #d1d5db',
      borderRadius: '0.375rem',
      backgroundColor: '#ffffff',
      color: '#000000',
      fontSize: '1rem',
      appearance: 'none',
      backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%236b7280'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
      backgroundPosition: 'right 0.5rem center',
      backgroundSize: '1.5em 1.5em',
      backgroundRepeat: 'no-repeat',
      paddingRight: '2.5rem',
      outline: 'none'
    },
    submitButton: {
      width: '100%',
      backgroundColor: '#3b82f6',
      color: '#ffffff',
      padding: '0.75rem 1rem',
      borderRadius: '0.375rem',
      fontWeight: '500',
      fontSize: '1rem',
      cursor: 'pointer',
      border: 'none',
      transition: 'background-color 0.2s',
      marginTop: '1.5rem'
    },
    focusedInput: {
      borderColor: '#3b82f6',
      boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.25)'
    }
  };

  // Dynamic style helpers
  const getFocusStyles = (e) => {
    e.target.style.borderColor = '#3b82f6';
    e.target.style.boxShadow = '0 0 0 3px rgba(59, 130, 246, 0.25)';
  };

  const getBlurStyles = (e) => {
    e.target.style.borderColor = '#d1d5db';
    e.target.style.boxShadow = 'none';
  };

  const getHoverButtonStyle = (e) => {
    e.target.style.backgroundColor = '#2563eb';
  };

  const getDefaultButtonStyle = (e) => {
    e.target.style.backgroundColor = '#3b82f6';
  };

  return (
    <div style={styles.formContainer}>
      <h2 style={styles.formTitle}>Intern Registration Form</h2>
      <ToastContainer />

      <form onSubmit={handleSubmit}>
        <div style={styles.formGrid}>
          {/* Name Field */}
          <div style={styles.inputGroup}>
            <label htmlFor="NAME" style={styles.label}>Name</label>
            <input
              type="text"
              id="NAME"
              value={formData.NAME}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>

          {/* Father Name Field */}
          <div style={styles.inputGroup}>
            <label htmlFor="FATHER_NAME" style={styles.label}>Father Name</label>
            <input
              type="text"
              id="FATHER_NAME"
              value={formData.FATHER_NAME}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>
        </div>

        <div style={styles.formGrid}>
          {/* Department Field */}
          <div style={styles.inputGroup}>
            <label htmlFor="department" style={styles.label}>Department</label>
            <input
              type="text"
              id="department"
              value={formData.department}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>

          {/* Registration No Field */}
          <div style={styles.inputGroup}>
            <label htmlFor="REG_NO" style={styles.label}>Registration No</label>
            <input
              type="text"
              id="REG_NO"
              value={formData.REG_NO}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>
        </div>

        <div style={styles.formGrid}>
          {/* Role Field */}
          <div style={styles.inputGroup}>
            <label htmlFor="Role" style={styles.label}>Role</label>
            <input
              type="text"
              id="Role"
              value={formData.Role}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>

          {/* Year of Passing Field */}
          <div style={styles.inputGroup}>
            <label htmlFor="yop" style={styles.label}>Year of Passing</label>
            <input
              type="text"
              id="yop"
              value={formData.yop}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>
        </div>

        <div style={styles.formGrid}>
          {/* Gender Dropdown */}
          <div style={styles.inputGroup}>
            <label htmlFor="GENDER" style={styles.label}>Gender</label>
            <select
              id="GENDER"
              value={formData.GENDER}
              onChange={handleChange}
              required
              style={styles.select}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            >
              <option value="">Select Gender</option>
              {genders.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </select>
          </div>

          {/* Course Certificate Type Dropdown */}
          <div style={styles.inputGroup}>
            <label htmlFor="Course_Certificate_Type_id" style={styles.label}>
              Course Certificate Type
            </label>
            <select
              id="Course_Certificate_Type_id"
              value={formData.Course_Certificate_Type_id}
              onChange={handleChange}
              required
              style={styles.select}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            >
              <option value="">Select Course Certificate Type</option>
              {Course_Certificate_Type_id.map((type) => (
                <option key={type} value={type}>
                  {courseCertificateOptions[type] || "Unknown Certificate"}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div style={styles.formGrid}>
          {/* Qualification Dropdown */}
          <div style={styles.inputGroup}>
            <label htmlFor="qualification" style={styles.label}>Qualification</label>
            <select
              id="qualification"
              value={formData.qualification}
              onChange={handleChange}
              required
              style={styles.select}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            >
              <option value="">Select Qualification</option>
              {qualifications.map((qual, index) => (
                <option key={index} value={qual}>
                  {qual}
                </option>
              ))}
            </select>
          </div>

          {/* College Dropdown */}
          <div style={styles.inputGroup}>
            <label htmlFor="college_select" style={styles.label}>College</label>
            <select
              id="college_select"
              onChange={handleCollegeChange}
              required
              style={styles.select}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            >
              <option value="">Select College</option>
              {colleges.map(college => (
                <option 
                  key={college._id} 
                  value={college._id}
                >
                  {college.name || "College Name Missing"}
                </option>
              ))}
            </select>
            <input type="hidden" id="college_id" value={formData.college_id} />
          </div>
        </div>

        <div style={styles.formGrid}>
          {/* Course Dropdown */}
          <div style={styles.inputGroup}>
            <label htmlFor="course_select" style={styles.label}>Course</label>
            <select
              id="course_select"
              onChange={handleCourseChange}
              required
              style={styles.select}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            >
              <option value="">Select Course</option>
              {courses.map(course => (
                <option 
                  key={course.courseId || course._id} 
                  value={course.courseId || ""}
                >
                  {course.courseName || course.name || "Course Name Missing"}
                </option>
              ))}
            </select>
            <input type="hidden" id="Course_id" value={formData.Course_id} />
          </div>

          {/* Certificate Type Dropdown */}
          <div style={styles.inputGroup}>
            <label htmlFor="certificate_select" style={styles.label}>Certificate Type</label>
            <select
              id="certificate_select"
              onChange={handleCertificateTypeChange}
              required
              style={styles.select}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            >
              <option value="">Select Certificate Type</option>
              {certificateTypes.map(certType => (
                <option 
                  key={certType._id} 
                  value={certType.Certificate_Type_id || certType.Course_Certificate_Type_id || ""}
                >
                  {certType.Certificate_Name || certType.Certification_Name || "Certificate Type Missing"}
                </option>
              ))}
            </select>
            <input type="hidden" id="Certificate_Type_id" value={formData.Certificate_Type_id} />
          </div>
        </div>

        <div style={styles.formGrid}>
          {/* From Date */}
          <div style={styles.inputGroup}>
            <label htmlFor="From" style={styles.label}>From Date</label>
            <input
              type="date"
              id="From"
              value={formData.From}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>

          {/* To Date */}
          <div style={styles.inputGroup}>
            <label htmlFor="To" style={styles.label}>To Date</label>
            <input
              type="date"
              id="To"
              value={formData.To}
              onChange={handleChange}
              required
              style={styles.input}
              onFocus={getFocusStyles}
              onBlur={getBlurStyles}
            />
          </div>
        </div>

        <button
          type="submit"
          style={styles.submitButton}
          onMouseOver={getHoverButtonStyle}
          onMouseOut={getDefaultButtonStyle}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default InternRegistrationForm;