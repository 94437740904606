import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CertificateGenerator from './CertificateGenerator';
import './Certificate.css';

function Certificate() {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedCollegeName, setSelectedCollegeName] = useState('');
  const [selectedYOP, setSelectedYOP] = useState('');
  const [selectedQualification, setSelectedQualification] = useState('');
  const [selectedCertificationType, setSelectedCertificationType] = useState('');
  const [collegeNames, setCollegeNames] = useState([]);
  const [yopList, setYOPList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRegNo, setSelectedRegNo] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/students');
      setStudents(response.data);
      console.log("Fetched students:", response.data);
      
      // Extract unique values
      const uniqueCollegeNames = [...new Set(response.data.map(student => student.college_name))];
      const uniqueYOPs = [...new Set(response.data.map(student => student.yop))];
      
      setCollegeNames(uniqueCollegeNames);
      setYOPList(uniqueYOPs);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    filterStudents(
      selectedCollegeName,
      selectedYOP,
      searchText,
      selectedRegNo,
      selectedQualification,
      selectedCertificationType
    );
  }, [
    students,
    selectedCollegeName,
    selectedYOP,
    searchText,
    selectedRegNo,
    selectedQualification,
    selectedCertificationType
  ]);

  const handleCollegeChange = (e) => setSelectedCollegeName(e.target.value);
  const handleYOPChange = (e) => setSelectedYOP(e.target.value);
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleRegNoChange = (e) => setSelectedRegNo(e.target.value);
  const handleQualificationChange = (e) => setSelectedQualification(e.target.value);
  const handleCertificationTypeChange = (e) => setSelectedCertificationType(e.target.value);

  const filterStudents = (collegeName, yop, searchText, regNo, qualification, certificationType) => {
    let filtered = [...students];

    if (collegeName) {
      filtered = filtered.filter(student => 
        student.college_name?.toLowerCase() === collegeName.toLowerCase()
      );
    }

    if (yop) {
      filtered = filtered.filter(student => student.yop === yop);
    }

    // Fixed qualification filter
    if (qualification) {
      filtered = filtered.filter(student => {
        const studentQual = student.qualification?.toLowerCase() || '';
        return studentQual === qualification.toLowerCase();
      });
    }

    // Fixed certificate type filter
    if (certificationType) {
      filtered = filtered.filter(student => {
        // Check both CERTIFICATION and certificate_type fields
        const certType = (student.CERTIFICATION || student.certificate_type || '').toLowerCase();
        return certType === certificationType.toLowerCase();
      });
    }

    if (searchText) {
      const searchLowerCase = searchText.toLowerCase();
      filtered = filtered.filter(student =>
        student.NAME?.toLowerCase().includes(searchLowerCase) ||
        student.REG_NO?.toLowerCase().includes(searchLowerCase) ||
        student.FATHER_NAME?.toLowerCase().includes(searchLowerCase) ||
        (student.COURSETITLE || student.coursename || '')?.toLowerCase().includes(searchLowerCase)
      );
    }

    if (regNo) {
      filtered = filtered.filter(student => student.REG_NO === regNo);
    }

    // Sort by name
    filtered.sort((a, b) => (a.NAME || '').localeCompare(b.NAME || ''));
    setFilteredStudents(filtered);
  };

  return (
    <>
      <section className="sub-header position-relative" style={{ height: "100px" }}>
        <div className="container">
          <div className="position-absolute" style={{
            marginTop: "-210px",
            maxWidth: "354px",
            width: "100",
            padding: "20px",
            left: "8%",
            top: "0",
            transform: "translate(-50%, 235px)",
            color: "black"
          }}>
            <h2 className="heading-2">Certificate</h2>
            <h6 className="heading-6">
              <a href="/dashboard">dashboard</a>
            </h6>
          </div>
        </div>
      </section>

      <div className="container" style={{ marginTop: "20px", marginBottom: "20px" }}>
        <div className="row g-3">
          <div className="col-md-2">
            <select 
              className="form-select" 
              value={selectedCollegeName} 
              onChange={handleCollegeChange}
            >
              <option value="">College Name</option>
              {collegeNames.map((collegeName, index) => (
                <option key={index} value={collegeName}>{collegeName}</option>
              ))}
            </select>
          </div>

          <div className="col-md-2">
            <select 
              className="form-select" 
              value={selectedYOP} 
              onChange={handleYOPChange}
            >
              <option value="">YOP</option>
              {yopList.map((yop, index) => (
                <option key={index} value={yop}>{yop}</option>
              ))}
            </select>
          </div>

          <div className="col-md-2">
            <select 
              className="form-select" 
              value={selectedQualification} 
              onChange={handleQualificationChange}
            >
              <option value="">Qualification</option>
              <option value="BE">BE</option>
              <option value="Diploma">Diploma</option>
            </select>
          </div>

          <div className="col-md-2">
            <select 
              className="form-select" 
              value={selectedCertificationType} 
              onChange={handleCertificationTypeChange}
            >
              <option value="">Certificate Type</option>
              <option value="INTERNSHIP">Internship</option>
              <option value="PROJECT">Project</option>
            </select>
          </div>

          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
            />
          </div>

          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              placeholder="Enter REG_NO..."
              value={selectedRegNo}
              onChange={handleRegNoChange}
            />
          </div>
        </div>
      </div>

      <div className="card_student_container">
        {filteredStudents.map((student, index) => (
          <div key={index}>
            <CertificateGenerator
              firstName={student.NAME || "N/A"}
              Gender={student.GENDER || "N/A"}
              CollegeName={student.college_name || "N/A"}
              FATHER_NAME={student.FATHER_NAME || "N/A"}
              REG_NO={student.REG_NO || "N/A"}
              coursename={student.COURSETITLE || student.coursename || "N/A"}
              certificate_type={student.CERTIFICATION || student.certificate_type || "N/A"}
              qualification={student.qualification || "Not Available"}
              department={student.department || "Not Available"}
              yop={student.yop || "N/A"}
              coursecertificatr={student.COURSECERTIFICATION || student.Course_Certificate_Type || "N/A"}
              Role={student.Role || "N/A"}
              From={student.From || "N/A"}
              To={student.To || "N/A"}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default Certificate;