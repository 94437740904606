// import React, { useState, useEffect, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import './InvoiceDisplay.css';

// const InvoiceDisplay = () => {
//   const [invoiceData, setInvoiceData] = useState(null);
//   const [error, setError] = useState(null);
//   const { id } = useParams();
//   const invoiceRef = useRef(null);

//   useEffect(() => {
//     const fetchInvoice = async () => {
//       try {
//         const response = await axios.get(`/invoices/${id}`);
//         setInvoiceData(response.data);
//       } catch (error) {
//         setError(error.response?.data?.message || 'An error occurred while fetching the invoice');
//       }
//     };

//     fetchInvoice();
//   }, [id]);

//   const handleDownload = () => {
//     const input = invoiceRef.current;
//     html2canvas(input, {
//       backgroundColor: null,
//       useCORS: true,
//       windowHeight: input.scrollHeight
//     }).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = pdf.internal.pageSize.getHeight();
//       const imgWidth = canvas.width;
//       const imgHeight = canvas.height;
//       const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
//       const imgX = (pdfWidth - imgWidth * ratio) / 2;
//       const imgY = 0;

//       pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
//       pdf.save('invoice.pdf');
//     });
//   };

//   if (error) return <div>Error: {error}</div>;
//   if (!invoiceData) return <div>Loading...</div>;

//   return (
//     <div className="invoice-wrapper">
//       <div className="invoice-form" ref={invoiceRef}>
//         <div className="header">
//           <div className="logo">
//           <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"100px",width:"170px",marginTop:"50px"}} />
//           </div>
//           <div className="company-info">
//             <h3>Excerpt Technologies Pvt Ltd</h3>
//             <p>B133/1, 2nd Fl, KSSDC ITI Estate, Whitefield Rd,</p>
//             <p>Mahadevpura Post, Bangalore - 560048</p>
//             <p>Phone: +91(636) 465 7660, +91 (990) 050 2404</p>
//             <p>GSTIN: 29AAGCE2327K1ZH</p>
//             <p>LUT No.: AD291021005439D</p>
//           </div>
//       <h2 style={{color:"white", marginTop:"80px"}}>INVOICE</h2>
//         </div>
//         {/* <div className="invoice-details">
//           <div className="left-details">
//             <p><strong>Invoice:</strong> {invoiceData.invoiceNumber}</p>
//             <p><strong>Date:</strong> {invoiceData.date}</p>
//             <p><strong>Terms:</strong> {invoiceData.terms}</p>
//             <p><strong>Due Date:</strong> {invoiceData.dueDate}</p>
//           </div>
//           <div className="right-details">
//             <div className="bill-to">
//               <p><strong>Bill To:</strong></p>
//               <p>{invoiceData.billTo}</p>
//             </div>
//             <div className="ship-to">
//               <p><strong>Ship To:</strong></p>
//               <p>{invoiceData.shipTo}</p>
//             </div>
//           </div>
//         </div> */}
//         <div className="invoice-details">
//   <p><strong>Invoice:</strong> {invoiceData.invoiceNumber}</p>
//   <p><strong>Date:</strong> {invoiceData.date}</p>
//   <p><strong>Terms:</strong> {invoiceData.terms}</p>
//   <p><strong>Due Date:</strong> {invoiceData.dueDate}</p>
// </div>
// <div className="address-details">
//   <div className="bill-to">
//     <p><strong>Bill To:</strong></p>
//     <p>{invoiceData.billTo}</p>
//   </div>
//   <div className="ship-to">
//     <p><strong>Ship To:</strong></p>
//     <p>{invoiceData.shipTo}</p>
//   </div>
// </div>
//         <table>
//           <thead>
//             <tr>
//               <th>Item & Description</th>
//               <th>Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr className="section-header">
//               <td colSpan="2">Part A - Website Design and Development</td>
//             </tr>
//             {invoiceData.webDesign && (
//               <tr>
//                 <td>1. Web design and development</td>
//                 <td>₹ {invoiceData.webDesign}</td>
//               </tr>
//             )}
//             {invoiceData.sslCertificate && (
//               <tr>
//                 <td>2. SSL Certificate</td>
//                 <td>₹ {invoiceData.sslCertificate}</td>
//               </tr>
//             )}
//             {(invoiceData.webDomain || invoiceData.webHosting) && (
//               <tr className="section-header">
//                 <td colSpan="2">Part B - Yearly Maintenance</td>
//               </tr>
//             )}
//             {invoiceData.webDomain && (
//               <tr>
//                 <td>3. Web domain charge (Yearly)</td>
//                 <td>₹ {invoiceData.webDomain}</td>
//               </tr>
//             )}
//             {invoiceData.webHosting && (
//               <tr>
//                 <td>4. Web hosting charge (Yearly)</td>
//                 <td>₹ {invoiceData.webHosting}</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//         <div className="invoice-summary">
//           <div className="total-in-words">
//             <p><strong>Total In Words:</strong> {invoiceData.totalInWords}</p>
//           </div>
//           <div className="totals">
//             <p><strong>Sub Total:</strong> ₹ {invoiceData.subTotal}</p>
//             <p><strong>CGST (9%):</strong> ₹ {invoiceData.cgst}</p>
//             <p><strong>SGST (9%):</strong> ₹ {invoiceData.sgst}</p>
//             <p><strong>Total:</strong> ₹ {invoiceData.total}</p>
//             <p><strong>Balance Due:</strong> ₹ {invoiceData.balanceDue}</p>
//           </div>
//         </div>
//         <div className="thank-you-message">
//           <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
//         </div>
//         <div className="terms-conditions">
//           <h4>Terms & Conditions</h4>
//           <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
//         </div>
//         <div className="authorized-signature">
//           <p>Authorized Signature</p>
//         </div>
//         <div className="bank-details">
//           <h4>Our Bank Details</h4>
//           <p>Name: Excerpt Technologies Private Limited</p>
//           <p>Account Number: 10063739760</p>
//           <p>Name of Bank: IDFC First Bank</p>
//           <p>IFSC Code: IDFB0080151</p>
//           <p>PAN No: AAGCE2327K</p>
//         </div>
//         <div className="footer">
//           <p><a href="https://excerpttech.com">https://excerpttech.com</a> | E-mail: info@excerpttech.com</p>
//         </div>
//       </div>
//       <div className="download-button-container">
//         <button onClick={handleDownload}>Download Invoice</button>
//       </div>
//     </div>
//   );
// };

// export default InvoiceDisplay;





// import React, { useState, useEffect, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import './InvoiceDisplay.css';

// const InvoiceDisplay = () => {
//   const [invoiceData, setInvoiceData] = useState(null);
//   const [error, setError] = useState(null);
//   const { id } = useParams();
//   const invoiceRef = useRef(null);

//   useEffect(() => {
//     const fetchInvoice = async () => {
//       try {
//         const response = await axios.get(`/invoices/${id}`);
//         setInvoiceData(response.data);
//       } catch (error) {
//         setError(error.response?.data?.message || 'An error occurred while fetching the invoice');
//       }
//     };

//     fetchInvoice();
//   }, [id]);

//   const handleDownload = () => {
//     const input = invoiceRef.current;
//     html2canvas(input, {
//       backgroundColor: null,
//       useCORS: true,
//       windowHeight: input.scrollHeight
//     }).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = pdf.internal.pageSize.getHeight();
//       const imgWidth = canvas.width;
//       const imgHeight = canvas.height;
//       const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
//       const imgX = (pdfWidth - imgWidth * ratio) / 2;
//       const imgY = 0;

//       pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
//       pdf.save('invoice.pdf');
//     });
//   };

//   if (error) return <div>Error: {error}</div>;
//   if (!invoiceData) return <div>Loading...</div>;

//   return (
//     <div className="invoice-display-wrapper" ref={invoiceRef}>
//       <div className="invoice-display-header">
//         <div className="invoice-display-logo">
//           {/* <img src="/Image/excerpt.png" alt="Excerpt Logo" /> */}
//           <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"80px",width:"170px",marginTop:"50px"}} />
//         </div>
//         <div className="invoice-display-company-info">
//           <h3>Excerpt Technologies Pvt Ltd</h3>
//           <p>B133/1, 2nd FL, KSSIDC ITI Estate, Whitefield Rd,</p>
//           <p>Mahadevpura Post, Bangalore – 560048</p>
//           <p>Phone:+91(636) 465 7660, +91 (990) 050 2404</p>
//           <p>GSTIN: 29AAGCE2327K1ZH</p>
//           <p>LUT No.: AD291021005439D</p>
//         </div>
//         <div className="invoice-display-title">
//         <h2>INVOICE</h2>
//         </div>
//       </div>
//       <div className="invoice-display-content">
//         <div className="invoice-display-details">
//           <p><strong>Invoice:</strong> <span className="field-value">{invoiceData.invoiceNumber}</span></p>
//           <p><strong>Date:</strong> <span className="field-value">{invoiceData.date}</span></p>
//           <p><strong>Terms:</strong> <span className="field-value">{invoiceData.terms}</span></p>
//           <p><strong>Due Date:</strong> <span className="field-value">{invoiceData.dueDate}</span></p>
//         </div>
//         <div className="invoice-display-address-details">
//           <div className="invoice-display-bill-to">
//             <p><strong>Bill To:</strong></p>
//             <p>{invoiceData.billTo}</p>
//           </div>
//           <div className="invoice-display-ship-to">
//             <p><strong>Ship To:</strong></p>
//             <p>{invoiceData.shipTo}</p>
//           </div>
//         </div>
//         <table className="invoice-display-table">
//           <thead>
//             <tr>
//               <th>#</th>
//               <th style={{ fontSize: "15px", fontWeight: "bold" }}>Item & Description</th>


//               <th style={{fontSize:"15px"}}>Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {invoiceData.webDesign && (
//               <tr>
//                 <td>1</td>
//                 <td><h6>Web design and development</h6></td>
//                 <td><h6>₹ {invoiceData.webDesign}</h6></td>
//               </tr>
//             )}
//             {invoiceData.sslCertificate && (
//               <tr>
//                 <td>2</td>
//                 <td><h6> SSL Certificate Installation</h6></td>
//                 <td><h6>₹ {invoiceData.sslCertificate}</h6></td>
//               </tr>
//             )}
//             {invoiceData.webDomain && (
//               <tr>
//                 <td>3</td>
//                 <td><h6>Web domain charge (Yearly)</h6></td>
//                 <td><h6>₹ {invoiceData.webDomain}</h6></td>
//               </tr>
//             )}
//             {invoiceData.webHosting && (
//               <tr>
//                 <td>4</td>
//                 <td><h6>Web hosting charge (Yearly)</h6></td>
//                 <td><h6>₹ {invoiceData.webHosting}</h6></td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//         <div className="invoice-display-summary">
//           <div className="invoice-display-total-in-words">
//             <p><strong>Total In Words</strong></p>
//             <p>{invoiceData.totalInWords}</p>
//           </div>
//           <div className="invoice-display-totals">
//             <p><h6><strong>Sub Total</strong> <span>₹ {invoiceData.subTotal}</span></h6></p>
//             <p><h6><strong>CGST (9%)</strong> <span>₹ {invoiceData.cgst}</span></h6></p>
//             <p><h6><strong>SGST (9%)</strong> <span>₹ {invoiceData.sgst}</span></h6></p>
//             <p className="total"><h6><strong>Total</strong> <span>₹ {invoiceData.total}</span></h6></p>
//           </div>
//         </div>
//         <div className="invoice-display-balance-due">
//           <p><h6><strong>Balance Due</strong> <span>₹ {invoiceData.balanceDue}</span></h6></p>
//         </div>
//         <div className="invoice-display-thank-you">
//           <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
//         </div>
//         <div className="invoice-display-signature">
//           <p>Authorized Signature</p>
//         </div>
//         <div className="invoice-display-terms">
//           <h4>Terms & Conditions</h4>
//           <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
//         </div>
//         <div className="invoice-display-bank-details">
//           <h4>Our Bank Details</h4>
//           <p>Name: Excerpt Technologies Private Limited</p>
//           <p>Account Number: 10063739760</p>
//           <p>Name of Bank: IDFC First Bank</p>
//           <p>IFSC Code: IDFB0080151</p>
//           <p>PAN No : AAGCE2327K</p>
//         </div>
//       </div>
//       <div className="invoice-display-footer">
//         <p><a href="https://excerptech.com">https://excerptech.com</a> | E-mail: info@excerptech.com</p>
//       </div>
//       <div className="invoice-display-download-button">
//         <button onClick={handleDownload}>Download Invoice</button>
//       </div>
//     </div>
//   );
// };

// export default InvoiceDisplay;






import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './InvoiceDisplay.css';

const InvoiceDisplay = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const invoiceContentRef = useRef(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get(`/invoices/${id}`);
        setInvoiceData(response.data);
      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred while fetching the invoice');
      }
    };

    fetchInvoice();
  }, [id]);

  const handleDownload = () => {
    const input = invoiceContentRef.current;
    html2canvas(input, {
      backgroundColor: null,
      useCORS: true,
      windowHeight: input.scrollHeight
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0;

      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('invoice.pdf');
    });
  };

  if (error) return <div>Error: {error}</div>;
  if (!invoiceData) return <div>Loading...</div>;

  return (
    <div className="invoice-display-wrapper">
      <div className="invoice-content" ref={invoiceContentRef}>
        <div className="invoice-display-header">
          <div className="invoice-display-logo">
            <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"80px",width:"170px",marginTop:"50px"}} />
          </div>
          <div className="invoice-display-company-info">
            <h3>Excerpt Technologies Pvt Ltd</h3>
            <p>B133/1, 2nd FL, KSSIDC ITI Estate, Whitefield Rd,</p>
            <p>Mahadevpura Post, Bangalore – 560048</p>
            <p>Phone:+91(636) 465 7660, +91 (990) 050 2404</p>
            <p>GSTIN: 29AAGCE2327K1ZH</p>
            <p>LUT No.: AD291021005439D</p>
          </div>
          <div className="invoice-display-title">
            <h2>INVOICE</h2>
          </div>
        </div>
        <div className="invoice-display-content">
          <div className="invoice-display-details">
            <p><strong>Invoice:</strong> <span className="field-value">{invoiceData.invoiceNumber}</span></p>
            <p><strong>Date:</strong> <span className="field-value">{invoiceData.date}</span></p>
            <p><strong>Terms:</strong> <span className="field-value">{invoiceData.terms}</span></p>
            <p><strong>Due Date:</strong> <span className="field-value">{invoiceData.dueDate}</span></p>
          </div>
          <div className="invoice-display-address-details">
            <div className="invoice-display-bill-to">
              <p><strong>Bill To:</strong></p>
              <p>{invoiceData.billTo}</p>
            </div>
            <div className="invoice-display-ship-to">
              <p><strong>Ship To:</strong></p>
              <p>{invoiceData.shipTo}</p>
            </div>
          </div>
          <table className="invoice-display-table">
            <thead>
              <tr>
                <th>#</th>
                <th style={{ fontSize: "15px", fontWeight: "bold" }}>Item & Description</th>
                <th style={{fontSize:"15px"}}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.webDesign && (
                <tr>
                  <td>1</td>
                  <td><h6>Web design and development</h6></td>
                  <td><h6>₹ {invoiceData.webDesign}</h6></td>
                </tr>
              )}
              {invoiceData.sslCertificate && (
                <tr>
                  <td>2</td>
                  <td><h6> SSL Certificate Installation</h6></td>
                  <td><h6>₹ {invoiceData.sslCertificate}</h6></td>
                </tr>
              )}
              {invoiceData.webDomain && (
                <tr>
                  <td>3</td>
                  <td><h6>Web domain charge (Yearly)</h6></td>
                  <td><h6>₹ {invoiceData.webDomain}</h6></td>
                </tr>
              )}
              {invoiceData.webHosting && (
                <tr>
                  <td>4</td>
                  <td><h6>Web hosting charge (Yearly)</h6></td>
                  <td><h6>₹ {invoiceData.webHosting}</h6></td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="invoice-display-summary">
            <div className="invoice-display-total-in-words">
              <p><strong>Total In Words</strong></p>
              <p>{invoiceData.totalInWords}</p>
            </div>
            <div className="invoice-display-totals">
              <p><h6><strong>Sub Total</strong> <span>₹ {invoiceData.subTotal}</span></h6></p>
              <p><h6><strong>CGST (9%)</strong> <span>₹ {invoiceData.cgst}</span></h6></p>
              <p><h6><strong>SGST (9%)</strong> <span>₹ {invoiceData.sgst}</span></h6></p>
              <p className="total"><h6><strong>Total</strong> <span>₹ {invoiceData.total}</span></h6></p>
            </div>
          </div>
          <div className="invoice-display-balance-due">
            <p><h6><strong>Balance Due</strong> <span>₹ {invoiceData.balanceDue}</span></h6></p>
          </div>
          <div className="invoice-display-thank-you">
            <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
          </div>
          <div className="invoice-display-signature">
            <p>Authorized Signature</p>
          </div>
          <div className="invoice-display-terms">
            <h4>Terms & Conditions</h4>
            <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
          </div>
          <div className="invoice-display-bank-details">
            <h4>Our Bank Details</h4>
            <p>Name: Excerpt Technologies Private Limited</p>
            <p>Account Number: 10063739760</p>
            <p>Name of Bank: IDFC First Bank</p>
            <p>IFSC Code: IDFB0080151</p>
            <p>PAN No : AAGCE2327K</p>
          </div>
        </div>
        <div className="invoice-display-footer">
          <p><a href="https://excerptech.com">https://excerptech.com</a> | E-mail: info@excerptech.com</p>
        </div>
      </div>
      <div className="invoice-display-download-button">
        <button onClick={handleDownload}>Download Invoice</button>
      </div>
    </div>
  );
};

export default InvoiceDisplay;