// import React, { useEffect, useState } from 'react';

// const 
// ResumeComponent = () => {
//   const [resumeData, setResumeData] = useState([]);

//   useEffect(() => {
//     const fetchResumeData = async () => {
//       try {
//         const response = await fetch('/api/resumedata');
//         const data = await response.json();
//         setResumeData(data);
//       } catch (error) {
//         console.error('Error fetching resume data:', error);
//       }
//     };

//     fetchResumeData();
//   }, []); // The empty dependency array ensures this effect runs once after the initial render

//   const handleDownload = async (resumePath) => {
//     try {
//       const response = await fetch(`/api/resumes/${resumePath}`);
//       const blob = await response.blob();
      
//       // Create a temporary anchor element and trigger a download
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement('a');
//       a.href = url;
//       a.download = resumePath; // Set the download file name
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } catch (error) {
//       console.error('Error downloading resume file:', error);
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center' }}>
//       <h2>Resume Data</h2>
//       <table style={{ width: '80%', borderCollapse: 'collapse', marginTop: '20px', border: '1px solid #ddd', margin: '0 auto' }}>
//         <thead>
//           <tr style={{ border: '1px solid #ddd' }}>
//             <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
//             <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
//             <th style={{ border: '1px solid #ddd', padding: '8px' }}>Job</th>
//             <th style={{ border: '1px solid #ddd', padding: '8px' }}>Resume File</th>
//             <th style={{ border: '1px solid #ddd', padding: '8px' }}>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {resumeData.map((resume) => (
//             <tr key={resume._id} style={{ border: '1px solid #ddd' }}>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>{resume.name}</td>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>{resume.email}</td>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>{resume.job}</td>
//               <td style={{ border: '1px solid #ddd', padding: '8px' }}>
//                 <a href={`/api/resumes/${resume.resumePath}`} target="_blank" rel="noopener noreferrer">
//                   {resume.resumePath}
//                 </a>
//               </td>
//               <td style={{ border: '1px solid #ddd', padding: '' }}>
//                 <button onClick={() => handleDownload(resume.resumePath)} style={{width:"110px"}}>Download</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ResumeComponent;

import React, { useEffect, useState } from 'react';

const ResumeComponent = () => {
  const [resumeData, setResumeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });

  useEffect(() => {
    const fetchResumeData = async () => {
      try {
        setLoading(true);
        const response = await fetch('/api/resumedata');
        if (!response.ok) {
          throw new Error('Failed to fetch resume data');
        }
        const data = await response.json();
        setResumeData(data);
      } catch (error) {
        console.error('Error fetching resume data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResumeData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Not available";
    
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "Not available";
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      return "Not available";
    }
  };

  const handleDownload = async (resumePath) => {
    try {
      const response = await fetch(`/api/resumes/${resumePath}`);
      
      if (!response.ok) {
        throw new Error(`Download failed: ${response.status} ${response.statusText}`);
      }
      
      const contentType = response.headers.get('content-type');
      const blob = await response.blob();
      
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: contentType || 'application/octet-stream' })
      );
      
      const a = document.createElement('a');
      a.href = url;
      a.download = resumePath.split('/').pop() || resumePath;
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading resume file:', error);
      alert(`Failed to download: ${error.message}`);
    }
  };

  // Search functionality
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Sorting functionality
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Items per page change handler
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Apply sorting and filtering
  const filteredData = resumeData.filter(item => {
    return (
      (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.email && item.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.job && item.job.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.resumePath && item.resumePath.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key === 'createdAt') {
      // Special handling for dates
      const aDate = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const bDate = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      
      if (sortConfig.direction === 'ascending') {
        return aDate - bDate;
      } else {
        return bDate - aDate;
      }
    } else {
      // Regular string/number comparison
      if (a[sortConfig.key] === undefined || a[sortConfig.key] === null) return 1;
      if (b[sortConfig.key] === undefined || b[sortConfig.key] === null) return -1;
      
      const aValue = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
      const bValue = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];
      
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    }
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Navigation to next and previous page
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Get sort indicator
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const tableStyles = {
    container: {
      padding: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    searchContainer: {
      marginBottom: '20px',
    },
    searchInput: {
      padding: '8px 12px',
      width: '300px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '14px',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      backgroundColor: 'white',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    th: {
      backgroundColor: '#f8f9fa',
      color: '#333',
      fontWeight: 'bold',
      padding: '12px 15px',
      textAlign: 'left',
      borderBottom: '2px solid #e0e0e0',
      cursor: 'pointer',
      userSelect: 'none',
      position: 'relative',
    },
    td: {
      padding: '10px 15px',
      borderBottom: '1px solid #e0e0e0',
      color: '#333',
    },
    tr: {
      transition: 'background-color 0.2s',
    },
    trHover: {
      backgroundColor: '#f5f5f5',
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '20px',
      padding: '10px',
      backgroundColor: '#f8f9fa',
      borderRadius: '4px',
      border: '1px solid #e0e0e0',
    },
    paginationControls: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    paginationButton: {
      padding: '8px 16px',
      backgroundColor: '#2196F3',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
      transition: 'background-color 0.2s',
    },
    paginationButtonDisabled: {
      backgroundColor: '#cccccc',
      cursor: 'not-allowed',
    },
    paginationInfo: {
      fontSize: '14px',
      color: '#555',
    },
    itemsPerPageContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    itemsPerPageLabel: {
      fontSize: '14px',
      color: '#555',
    },
    itemsPerPageSelect: {
      padding: '6px 10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      backgroundColor: 'white',
      fontSize: '14px',
    },
    fileName: {
      color: '#2196F3',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    loadingText: {
      textAlign: 'center',
      padding: '30px',
      color: '#666',
      fontSize: '16px',
    },
    downloadButton: {
      padding: '8px 16px',
      backgroundColor: '#1d4a8e',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      width:'100px',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'background-color 0.2s',
    },
    summary: {
      marginTop: '10px',
      fontSize: '14px',
      color: '#666',
    },
    emptyMessage: {
      textAlign: 'center',
      padding: '30px',
      color: '#666',
      fontSize: '16px',
      border: '1px dashed #ddd',
      borderRadius: '4px',
      backgroundColor: '#f9f9f9',
    }
  };

  if (loading) {
    return <div style={tableStyles.loadingText}>Loading resume data...</div>;
  }

  return (
    <div style={tableStyles.container}>
      <div style={tableStyles.header}>
        <h2>Resume Data</h2>
        <div style={tableStyles.searchContainer}>
          <input
            type="text"
            placeholder="Search by name, email, job..."
            value={searchTerm}
            onChange={handleSearch}
            style={tableStyles.searchInput}
          />
        </div>
      </div>

      {currentItems.length === 0 ? (
        <div style={tableStyles.emptyMessage}>
          {searchTerm ? 'No resumes match your search criteria' : 'No resume data available'}
        </div>
      ) : (
        <>
          <div style={{ overflowX: 'auto' }}>
            <table style={tableStyles.table}>
              <thead>
                <tr>
                  <th 
                    style={tableStyles.th} 
                    onClick={() => requestSort('name')}
                  >
                    Name {getSortIndicator('name')}
                  </th>
                  <th 
                    style={tableStyles.th} 
                    onClick={() => requestSort('email')}
                  >
                    Email {getSortIndicator('email')}
                  </th>
                  <th 
                    style={tableStyles.th} 
                    onClick={() => requestSort('job')}
                  >
                    Job {getSortIndicator('job')}
                  </th>
                  <th 
                    style={tableStyles.th} 
                    onClick={() => requestSort('createdAt')}
                  >
                    Created {getSortIndicator('createdAt')}
                  </th>
                  <th style={tableStyles.th}>Resume File</th>
                  <th style={tableStyles.th}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((resume, index) => (
                  <tr 
                    key={resume._id || index}
                    style={tableStyles.tr}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#f5f5f5';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = '';
                    }}
                  >
                    <td style={tableStyles.td}>{resume.name || 'N/A'}</td>
                    <td style={tableStyles.td}>{resume.email || 'N/A'}</td>
                    <td style={tableStyles.td}>{resume.job || 'N/A'}</td>
                    <td style={tableStyles.td}>{formatDate(resume.createdAt)}</td>
                    <td style={tableStyles.td}>
                      {resume.resumePath ? (
                        <span 
                          style={tableStyles.fileName}
                          onClick={() => handleDownload(resume.resumePath)}
                        >
                          {resume.resumePath.split('/').pop() || resume.resumePath}
                        </span>
                      ) : (
                        'No file'
                      )}
                    </td>
                    <td style={tableStyles.td}>
                      {resume.resumePath ? (
                        <button 
                          onClick={() => handleDownload(resume.resumePath)} 
                          style={tableStyles.downloadButton}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#1d4a8e';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = '#1d4a8e';
                          }}
                        >
                          Download
                        </button>
                      ) : (
                        <span style={{ color: '#999' }}>No file</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={tableStyles.summary}>
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, sortedData.length)} of {sortedData.length} entries
          </div>

          <div style={tableStyles.paginationContainer}>
            <div style={tableStyles.itemsPerPageContainer}>
              <span style={tableStyles.itemsPerPageLabel}>Items per page:</span>
              <select 
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={tableStyles.itemsPerPageSelect}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
            
            <div style={tableStyles.paginationInfo}>
              Page {currentPage} of {totalPages}
            </div>
            
            <div style={tableStyles.paginationControls}>
              <button
                onClick={goToPrevPage}
                disabled={currentPage === 1}
                style={{
                  ...tableStyles.paginationButton,
                  ...(currentPage === 1 ? tableStyles.paginationButtonDisabled : {})
                }}
              >
                Prev
              </button>
              
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages || totalPages === 0}
                style={{
                  ...tableStyles.paginationButton,
                  ...(currentPage === totalPages || totalPages === 0 ? tableStyles.paginationButtonDisabled : {})
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ResumeComponent;
