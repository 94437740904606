import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import QRCode from 'qrcode';
import NunitoBold from '../Fonts/NunitoSans_7pt-Bold.ttf';
import NunitoBold1 from '../Fonts/Sanchez-Regular.ttf'; // Ensure this path is correct
import '../Studentcertificate.js/Studentcertificate.css';

function CourseStudentList({ students }) {
    const [courseStudents, setCourseStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDateMap, setSelectedDateMap] = useState({});
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedYOP, setSelectedYOP] = useState('');
    const [selectedCollege, setSelectedCollege] = useState('');
    const [courses, setCourses] = useState([]);
    const [years, setYears] = useState([]);
    const [colleges, setColleges] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get('/api/courseStudents');
                setCourseStudents(response.data);
                setLoading(false);

                // Extracting unique course names, years of passing, and college names
                const uniqueCourses = [...new Set(response.data.map(student => student.course))];
                const uniqueYears = [...new Set(response.data.map(student => student.yop))];
                const uniqueColleges = [...new Set(response.data.map(student => student.collegeName))];

                setCourses(uniqueCourses);
                setYears(uniqueYears);
                setColleges(uniqueColleges);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const filteredStudents = courseStudents.filter(student => 
        (selectedCourse ? student.course === selectedCourse : true) &&
        (selectedYOP ? student.yop === selectedYOP : true) &&
        (selectedCollege ? student.collegeName === selectedCollege : true)
    );

    const handleCourseChange = (e) => {
        setSelectedCourse(e.target.value);
    };

    const handleYOPChange = (e) => {
        setSelectedYOP(e.target.value);
    };

    const handleCollegeChange = (e) => {
        setSelectedCollege(e.target.value);
    };

    const splitTextIntoLines = (text, maxWidth, font, fontSize) => {
        const words = text.split(' ');
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const testLine = currentLine + ' ' + word;
            const testWidth = font.widthOfTextAtSize(testLine, fontSize);

            if (testWidth <= maxWidth) {
                currentLine = testLine;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);

        return lines;
    };

    const downloadCertificate = async (student) => {
        const { name, fatherName, course, softwareCovered, regNo, collegeName, from, to, gender } = student;
        const selectedDate = selectedDateMap[name] || '';
        if (!selectedDate) {
            alert('Please select a date before downloading the certificate.');
            return;
        }

        try {
            const existingPdfBytes = await fetch('/cert30.pdf').then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);
            const fontBytes = await fetch(NunitoBold).then(res => res.arrayBuffer());
            const customFont = await pdfDoc.embedFont(fontBytes);
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const fontBytesStudentName = await fetch(NunitoBold1).then(res => res.arrayBuffer());
            const customFontStudentName = await pdfDoc.embedFont(fontBytesStudentName);

            const upperCaseStudentName = name ? name.toUpperCase() : '';
            const upperCasecollegeName = collegeName ? collegeName.toUpperCase() : '';

            const upperCaseSoftwareCovered = softwareCovered ? softwareCovered.toUpperCase() : '';

            let title, pronoun,pronoun1, middleText, conclusionText;

            if (gender === 'Male') {
                title = 'Mr.';
                pronoun = 'him';
                pronoun1='his';
                middleText = ` bearing Registration Number: ${regNo}, a student of ${upperCasecollegeName}, has successfully completed an internship course from ${from} to ${to} at EXCERPT TECHNOLOGIES PVT LTD. During his internship, `;
                conclusionText = `${pronoun.charAt(0).toUpperCase()}${pronoun1.slice(1)} conduct during ${pronoun1} stay with us was satisfactory. We wish ${pronoun} all the best for ${pronoun1} future endeavors.`;
            } else if (gender === 'Female') {
                title = 'Ms.';
                pronoun = 'her';
                middleText = ` bearing Registration Number: ${regNo}, a student of ${upperCasecollegeName}, has successfully completed an internship course from ${from} to ${to} at EXCERPT TECHNOLOGIES PVT LTD. During her internship, `;
                conclusionText = `${pronoun.charAt(0).toUpperCase()}${pronoun.slice(1)} conduct during ${pronoun} stay with us was satisfactory. We wish ${pronoun} all the best for ${pronoun} future endeavors.`;
            } else {
                console.error('Invalid gender:', gender);
                return;
            }

            const introText = `This is to certify that `;
            const nameText = `${title} ${upperCaseStudentName}`;
            const endingText = ` worked as an Intern in "${softwareCovered}" and gained experience in the following areas:`;

            const maxWidth = 460;
            const fontSize = 12;
            const lineHeight = 23;
            const lineGap = 5;
            let yPos = firstPage.getHeight() - 300;

            const drawTextWithHighlight = (text, isHighlighted, isBold, x, y) => {
                const color = isHighlighted ? rgb(1, 1, 1) : rgb(0, 0, 0);
                const fontWeight = isBold ? 'bold' : 'normal';
                const parts = text.split(/(Mr\.|Miss\.)/); // Split the text by title
                let currentX = x;

                parts.forEach(part => {
                    const width = customFont.widthOfTextAtSize(part, fontSize);
                    firstPage.drawText(part, {
                        x: currentX,
                        y,
                        size: fontSize,
                        font: customFont,
                        color,
                        fontWeight, // Apply bold font weight if necessary
                    });
                    currentX += width;
                });
            };


            let lines = splitTextIntoLines(introText + nameText + middleText + nameText + endingText, maxWidth, customFont, fontSize);

            let currentX = 70;
            let currentY = yPos;

            lines.forEach((line, index) => {
                drawTextWithHighlight(line, false, true, currentX, currentY - (index * (lineHeight + lineGap))); // Highlight and bold the student's name
            });


            currentY -= lines.length * (lineHeight + lineGap) + lineGap;

            const bulletPointLines = [
                "• Research and analysis",
                "• Writing Code",
                "• Preparing Documentation"
            ];

            bulletPointLines.forEach((line, index) => {
                firstPage.drawText(line, {
                    x: 70,
                    y: currentY - (index * (lineHeight + lineGap)),
                    size: fontSize,
                    font: customFont,
                    color: rgb(0, 0, 0),
                });
            });

            currentY -= bulletPointLines.length * (lineHeight + lineGap) + lineGap;

            const conclusionLines = splitTextIntoLines(conclusionText, maxWidth, customFont, fontSize);

            conclusionLines.forEach((line, index) => {
                firstPage.drawText(line, {
                    x: 70,
                    y: currentY - (index * (lineHeight + lineGap)),
                    size: fontSize,
                    font: customFont,
                    color: rgb(0, 0, 0),
                });
            });
            const qrCodeData = `https://www.excerptech.com/certificate1.html?REG_NO=${regNo}`;
            const qrCodeDataURL = await QRCode.toDataURL(qrCodeData);

            const base64Data = qrCodeDataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
            const qrCodeImage = await pdfDoc.embedPng(base64Data);

            const qrCodeWidth = 80;
            const qrCodeHeight = 80;

            firstPage.drawImage(qrCodeImage, {
                x: 65,
                y: 80,
                width: qrCodeWidth,
                height: qrCodeHeight
            });

            const modifiedPdfBytes = await pdfDoc.save();
            const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
           
                const a = document.createElement('a');
                a.href = url;
                a.download = `Certificate_${name}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error generating certificate:', error.message);
            }
        };
    
        const handleDateChange = (e, studentName) => {
            const { value } = e.target;
            setSelectedDateMap(prevState => ({
                ...prevState,
                [studentName]: value,
            }));
        };
    
        return (
            <div>
                <div className="filter-container" style={{display:"flex"}}>
                    <div className="filter">
                        <label>Courses:</label>
                        <select value={selectedCourse} onChange={handleCourseChange}>
                            <option value="">All Courses</option>
                            {courses.map((course, index) => (
                                <option key={index} value={course}>{course}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label>Year of Passing:</label>
                        <select value={selectedYOP} onChange={handleYOPChange}>
                            <option value="">All Years</option>
                            {years.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label>College Name:</label>
                        <select value={selectedCollege} onChange={handleCollegeChange}>
                            <option value="">All Colleges</option>
                            {colleges.map((college, index) => (
                                <option key={index} value={college}>{college}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="card-container">
                    {filteredStudents.map((student, index) => (
                        <div key={index} className="card">
                            <div>
                                <img
                                    src={`/${student.regNo}.png`}
                                    alt={`Image of ${student.name}`}
                                    style={{ width: '100px', height: '100px', borderRadius: "50%" }}
                                />
                                <div className="info-container">
                                    <div className="label">Name:</div>
                                    <h3>{student.name.toUpperCase()}</h3>
                                    <div className="label">Course:</div>
                                    <p>{student.course}</p>
                                    <div className="label">Year of Passing:</div>
                                    <p>{student.yop}</p>
                                    <div className="label">Software Covered:</div>
                                    <p>{student.softwareCovered}</p>
                                    <div className="label">regNo:</div>
                                    <p>{student.regNo}</p>
                                    <div className="label">College Name:</div>
                                    <p>{student.collegeName}</p>
                                    <div className="label">From Date:</div>
                                    <p>{student.from}</p>
                                    <div className="label">To Date:</div>
                                    <p>{student.to}</p>
                                </div>
                            </div>
                            <div className="button-container">
                                <input
                                    type="date"
                                    value={selectedDateMap[student.name] || ''}
                                    onChange={(e) => handleDateChange(e, student.name)}
                                />
                                <a href={`https://excerptech.com/certificate1.html?REG_NO=${student.regNo}`} target="_blank" rel="noopener noreferrer">
                                    View Certificate
                                </a>
                            </div>
                            <button onClick={() => downloadCertificate(student)}>Download Certificate</button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    
    export default CourseStudentList;
    